<kritsnam-clients-view
style="width: 100%; height: 100%"
[data]="data"
[uiState]="uiState"
[errorText]="errorText"
[snackbar]="snackBar"
(openClick)="onClickOpen($event)"
(resetClick)="onClickReset()"
(setPasswordClick)="onClickSetPassword($event)"
></kritsnam-clients-view>
