import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertEditPopupComponent, AlertEditPopupResult } from 'src/app/components/alert-edit-popup/alert-edit-popup.component';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { AlertModel, AlertViewModel } from 'src/app/models/alert_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { SELF_KEYWORD, ViewUiState } from 'src/app/utils/constants';
import * as crypto from 'src/app/utils/crypto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-alerts',
  templateUrl: './user-alerts.component.html',
  styleUrls: ['./user-alerts.component.scss']
})
export class UserAlertsComponent extends BaseComponent implements OnInit {

  userId: string;
  user: UserViewModel;
  mappings: Array<MappingViewModel>;
  selectedMapping: MappingViewModel;
  bannerText: string;
  data: {users: UserViewModel[], alerts: AlertViewModel[]} = { users: [], alerts: [] };
  alerts$: Subscription;

  inProgressCounter = 0;

  constructor(
    scrollingService: ScrollingService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    router: Router,
    butler: DataService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.parent.paramMap.get('id');
    if(this.userId === SELF_KEYWORD) {
      if(this.butler.localService.isUserKritsnam()) this.router.navigate(["/"]);
      this.userId = this.butler.localService.getLocalUserId();
    }
    this.handleBanner();
  }

  handleBanner() {
    // Get query params, if banner present, check that it is verified, if yes, pass it to the view.
    if(this.route.snapshot.queryParams.banner && this.route.snapshot.queryParams.bannerSignature) {
      const bannerText = this.route.snapshot.queryParams.banner;
      const bannerSignature = this.route.snapshot.queryParams.bannerSignature;
      const isVerified = crypto.verifySign(bannerText, bannerSignature, environment.bannerPubKey);
      if(isVerified) this.bannerText = bannerText;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { banner: null, bannerSignature: null },
        queryParamsHandling: 'merge'
      }); // Remove from url
    }
  }

  ngOnInit(): void {
    this.fetchData()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  private unsubscribeAll() {
    this.alerts$?.unsubscribe();
  }

  private async fetchData() {
    try {
      this.uiState = ViewUiState.loading;
      const currentUserId = this.butler.localService.getLocalUserId();
      const currentUserOrg = this.butler.localService.getUserOrg();
      const user = await this.butler.userApi.getCachedUser(this.userId);
      this.user = new UserViewModel(user, currentUserId, currentUserOrg);
      const mappings = await this.butler.userApi.getUserMappings(this.userId);
      this.mappings = mappings.map(m => new MappingViewModel(m));
      const allUsers = await this.butler.userApi.getCompanyUsers(user.companyExternalId);
      this.data.users = allUsers.map(u => new UserViewModel(u, currentUserId, currentUserOrg));
      // If a valid device id is present in query params, select that device
      if(this.route.snapshot.queryParams.device) {
        this.selectedMapping = this.mappings.find(m => m.deviceExternalId === this.route.snapshot.queryParams.device);
      }
      // Default to the first mapping if no device is selected
      if(!this.selectedMapping) {
        this.selectedMapping = this.mappings[0];
      }
      this.fetchAlerts();
    } catch (error) {
      this.errorText = "Failed to fetch data, please try again.";
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  async updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        device: this.selectedMapping.deviceExternalId,
      },
      queryParamsHandling: 'merge'
    });
  }

  private fetchAlerts() {
    this.updateQueryParams();
    this.unsubscribeAll();
    this.uiState = ViewUiState.loading;
    this.alerts$ = this.butler.deviceApi
    .getAlertsObservable(this.userId, this.selectedMapping.deviceExternalId)
    .subscribe((alerts: AlertModel[]) => {
        const alertViewModels = alerts.map(c => new AlertViewModel(c));
        this.data.alerts = alertViewModels;
        this.uiState = ViewUiState.data;
      },
      (error) => {
        this.errorText = "Failed to fetch data, please try again.";
        this.uiState = ViewUiState.error;
        this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
      }
    );
  }

  public async onClickMappingChange(mapping: MappingViewModel) {
    this.selectedMapping = mapping;
    this.fetchAlerts();
  }

  public async onClickNewAlert() {
    const dialogRef = this.dialog.open(AlertEditPopupComponent, {
      width: '800px',
      maxWidth: '95vw',
      data: {
        deviceExternalId: this.selectedMapping.deviceExternalId,
        allUsers: this.data.users,
        allAlerts: this.data.alerts,
        alert: null,
      }
    });
    const result: AlertEditPopupResult = await dialogRef.afterClosed().toPromise();
    if(!result) return;
    this.inProgressCounter++;
    try {
      await this.butler.deviceApi.createAlert(
        this.selectedMapping.deviceExternalId,
        result.priority,
        result.target,
        result.period,
        result.threshold,
        result.emails,
        result.phones,
        result.fcms,
      );
    } catch (error) {
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
    this.inProgressCounter--;
  }

  public async onClickEditAlert(alert: AlertViewModel) {
    const dialogRef = this.dialog.open(AlertEditPopupComponent, {
      width: '800px',
      maxWidth: '95vw',
      data: {
        deviceExternalId: this.selectedMapping.deviceExternalId,
        allUsers: this.data.users,
        allAlerts: this.data.alerts,
        alert: alert,
      }
    });
    const result: AlertEditPopupResult = await dialogRef.afterClosed().toPromise();
    if(!result) return;
    this.inProgressCounter++;
    try {
      await this.butler.deviceApi.editAlert(
        this.selectedMapping.deviceExternalId,
        alert.id,
        result.priority,
        result.target,
        result.period,
        result.threshold,
        result.emails,
        result.phones,
        result.fcms,
      );
      this.snackBar = {message: `Alert edited`, action: 'Close', config: { duration: 4000 }};
    } catch (error) {
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
    this.inProgressCounter--;
  }

  public async onClickDeleteAlert(alert: AlertViewModel) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '400px',
      data: {
        title: 'Delete Alert',
        message: `Are you sure you want to delete alert ${alert.alertCondition}?`,
        confirmColor: 'warn',
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    if(!result) return;
    this.inProgressCounter++;
    try {
      await this.butler.deviceApi.deleteAlert(this.selectedMapping.deviceExternalId, alert.id);
      this.snackBar = {message: `Alert deleted`, action: 'Close', config: { duration: 4000 }};
    } catch (error) {
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
    this.inProgressCounter--;
  }

  public async onClickTestAlert(alert: AlertViewModel) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '400px',
      data: {
        title: 'Test Alert',
        message: `Are you sure you want to test alert ${alert.alertCondition}? It will send a test alert to ${alert.emails.length} emails, ${alert.phones.length} phones and ${alert.fcms.length} app notifications.`,
        confirmColor: 'primary',
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    if(!result) return;
    this.inProgressCounter++;
    try {
      await this.butler.deviceApi.testAlert(this.selectedMapping.deviceExternalId, alert.id);
      this.snackBar = {message: `Test alert sent!`, action: 'Close', config: { duration: 4000 }};
    } catch (error) {
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
    this.inProgressCounter--;
  }

  public async onClickReset() {
    this.fetchData();
  }
}
