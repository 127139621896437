<div id="kritsnam-notification-page" fxFlex="1 1 auto" fxLayout="column">
  <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
  </app-loading-view>

  <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}"
  [errorText]="errorText" (resetClick)="onClickReset()">
  </app-error-view>

  <single-column-view *ngIf="uiState == ViewUiState.data"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    <mat-card-title style="padding: 16px;">Send Notification Broadcast</mat-card-title>
    <mat-card-content
    [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}"
    [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 16, 'padding-bottom.px': 16, 'padding-left.px': 16}">
      <form [formGroup]="notificationForm" (submit)="onClickFormSubmit()" fxLayout="column">
        <mat-form-field appearance="fill" style="padding-bottom: 16px">
          <mat-label>Title</mat-label>
          <input matInput type="text" formControlName="title">
          <mat-error *ngIf="notificationForm.controls.title.invalid">
            {{getErrorMessage(notificationForm.controls.title)}}
          </mat-error>
          <mat-hint>Title of the notification</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" style="padding-bottom: 16px">
          <mat-label>Message</mat-label>
          <textarea matInput formControlName="message"></textarea>
          <mat-error *ngIf="notificationForm.controls.message.invalid">
            {{getErrorMessage(notificationForm.controls.message)}}
          </mat-error>
          <mat-hint>Body of the notification</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" style="padding-bottom: 16px">
          <mat-label>URL</mat-label>
          <input matInput type="text" formControlName="url">
          <mat-error *ngIf="notificationForm.controls.url.invalid">
            {{getErrorMessage(notificationForm.controls.url)}}
          </mat-error>
          <mat-hint>URL to open on click. Include http:// or https://</mat-hint>
        </mat-form-field>
        <button mat-button color="primary" type="submit"
        [disabled]="!notificationForm.valid && !notificationForm.disabled">Submit</button>
      </form>
    </mat-card-content>
  </single-column-view>
</div>
