import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { MatChipInput, MatChipInputEvent, MatChipList } from '@angular/material/chips';

/**
 * Directive to add a custom chip input to a material chip list.
 * Support chip input on android phones
 * https://stackblitz.com/edit/chipinput?file=app%2Fandroid-mat-chip-input.directive.ts
 */
@Directive({
  selector: 'input[androidMatChipInputFor]'
})
export class AndroidMatChipInput extends MatChipInput {

  @HostBinding('class')
  cssClasses = 'mat-chip-input mat-input-element';

  @Input('androidMatChipInputFor')
  set chipList(value: MatChipList) {
    if (value) {
      this._chipList = value;
      this._chipList.registerInput(this);
    }
  }

  @Input('matChipInputAddOnBlur')
  get addOnBlur() { return this._addOnBlur; }
  set addOnBlur(value: boolean) { this._addOnBlur = coerceBooleanProperty(value); }

  @Input() placeholder: string = '';

  @Output('matChipInputTokenEnd')
  chipEnd = new EventEmitter<MatChipInputEvent>();

  @HostListener('keyup', ['$event'])
  keyup(event?: KeyboardEvent) {
    // normal check
    if ((this.addOnBlur && !event) || new Set(this.separatorKeyCodes).has(event.keyCode)) {
      this.chipEnd.emit({ input: this._inputElement, value: this._inputElement.value });
        if (event) {
          event.preventDefault();
        }
    }

    // android
    if (event && this._inputElement.value) {
      const value = this._inputElement.value;
      const lastKeyCode = value[value.length - 1].charCodeAt(0);
      if (event.keyCode == 229 && new Set(this.separatorKeyCodes).has(lastKeyCode)) {
        this.chipEnd.emit({ input: this._inputElement, value: this._inputElement.value });
        if (event) {
          event.preventDefault();
        }
      }
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event?: KeyboardEvent) {
    if (!this._inputElement.value && !!event) {
      this._chipList._keydown(event);
    }
  }

  @HostListener('blur')
  blur() {
    this._blur();
  }

  @HostListener('focus')
  focus() {
    this._focus();
  }

  @HostListener('input')
  onInput() {
    this._onInput();
  }

}
