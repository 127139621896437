import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { MyErrorStateMatcher } from 'src/app/utils/constants';
import { environment } from 'src/environments/environment';

var grecaptcha: any; // Global set by firebase

export enum LoginViewUiState {
  unselected,
  phone,
  otp,
  loading,
  error,
  password,
}

@Component({
  selector: 'login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss']
})
export class LoginViewComponent extends BaseViewComponent implements OnInit {
  LoginViewUiState = LoginViewUiState; // For access in HTML

  @Input('cachedPhoneNumber')
  cachedPhoneNumber: string;

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  @Output('submitPhoneClick')
  submitPhoneClick = new EventEmitter<{phone: string}>();

  @Output('submitOTPClick')
  submitOTPClick = new EventEmitter<{code: string}>();

  @Output('passwordLoginClick')
  passwordLoginClick = new EventEmitter<boolean>();

  @Input('loginViewUiState')
  loginViewUiState: LoginViewUiState;

  @Output('submitPasswordClick')
  submitPasswordClick = new EventEmitter<{id: string, captcha: string, password: string}>();

  phoneForm: FormGroup;
  otpForm: FormGroup;
  loginOptionsForm: FormGroup;
  ocdErrorMatcher = new MyErrorStateMatcher();
  recaptchaVerified: boolean = false;
  hidePassword: boolean = true;

  passwordForm: FormGroup;

  constructor(
    scrollingService: ScrollingService,
    private readonly formBuilder: FormBuilder,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {
    this.initForms();
    this.initRecaptchaVerifier();
  }

  initRecaptchaVerifier() {
    if(environment.test) return this.recaptchaVerified = true; // In testing we assume captcha to be verified
    const auth = getAuth();
    (window as any).recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response) => this.recaptchaVerified = true,
      'expired-callback': () => this.recaptchaVerified = false,
    }, auth);
    (window as any).recaptchaVerifier.render().then((widgetId) => {
      (window as any).recaptchaWidgetId = widgetId;
      this.recaptchaStyler();
    });
  }

  resetRecaptchaVerifier() {
    const recaptchaStyler = this.recaptchaStyler; // Pass function down in callback
    const appVerifier: RecaptchaVerifier = (window as any).recaptchaVerifier;
    if(appVerifier) {
      appVerifier.render().then(function(widgetId) {
        grecaptcha?.reset(widgetId);
        recaptchaStyler();
      });
    }
  }

  /**
   * Custom CSS to make recaptcha responsive
   * ! This is a hacky way to do this
   * ! But it works
   * We are using the ratio calculated from the firebase recaptcha to correctly size the custom recaptcha
   * We are scaling up both recaptcha to fit the screen width
  */
    recaptchaStyler() {
    let ratio: any, padding: any;
    document.querySelectorAll('[title="reCAPTCHA"]').forEach((scaled: any) => {
      scaled = scaled.parentNode;
      scaled = scaled.parentNode;
      let parent: any = scaled.parentNode;
      if(parent.offsetWidth === 0) parent = parent.parentNode; // Special case for <re-captcha/>
      ratio = ratio? ratio: (parent.offsetWidth / scaled.offsetWidth); // Calculate only once
      padding = padding? padding: scaled.offsetHeight * ratio; // Calculate only once
      scaled.style.transform = 'scale(' + ratio + ')';
      scaled.style.transformOrigin = 'top left';
      if(ratio >= 1) parent.style.marginBottom = '16px'; // keep appropriate margin on desktop
      parent.style.paddingTop = padding; // keeps the parent height in ratio to child resize
    });
  }

  initForms() {
    this.phoneForm = this.formBuilder.group({
      phone: [this.cachedPhoneNumber, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('[0-9]*'),
      ]]
    });
    this.otpForm = this.formBuilder.group({
      otp: [null, Validators.required]
    });
    this.passwordForm = this.formBuilder.group({
      id: [this.cachedPhoneNumber, Validators.required],
      captcha: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  async onClickReset() {
    this.resetClick.emit(true);
  }

  async onClickSubmitPhone() {
    if(!this.recaptchaVerified) {
      this.snackBar.open('Please verify that you are not a robot', 'Close', { duration: 4000 });
      return this.resetRecaptchaVerifier();
    }
    if(this.phoneForm.invalid) {
      return this.snackBar.open('Please input a valid phone number', 'Close', { duration: 4000 });
    }
    const phone = this.phoneForm.controls.phone.value;
    this.submitPhoneClick.emit({phone: `+91${phone}`});
  }

  async onClickSubmitOTP() {
    const code = this.otpForm.controls.otp.value;
    this.submitOTPClick.emit({code: code});
  }

  async onClickPasswordLogin() {
    this.passwordLoginClick.emit(true);
  }

  async onClickSubmitPassword() {
    this.submitPasswordClick.emit(this.passwordForm.value);
    this.passwordForm.controls.captcha.setValue('');
    this.recaptchaStyler();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('pattern')) {
      const error = control.getError('pattern');
      if(error.requiredPattern.includes('[0-9]*')) {
        return 'Enter a 10 digit phone number';
      } else {
      return 'You must enter a valid value';
      }
    }

    if (control.hasError('min')) {
      const min = control.getError('min').min
      return `You must enter a value greater than ${min}`;
    }

    if (control.hasError('max')) {
      const max = control.getError('max').max
      return `You must enter a value smaller than ${max}`;
    }

    if (control.hasError('required')) {
      return 'You must enter a value';
    }

    if (control.hasError('email')) {
      return 'You must enter a valid email address';
    }

    if (control.hasError('maxlength')) {
      const actualLength = control.getError('maxlength').actualLength
      const requiredLength = control.getError('maxlength').requiredLength
      return `Input value is too long. Length ${actualLength}/${requiredLength}`;
    }

    if (control.hasError('minlength')) {
      const actualLength = control.getError('minlength').actualLength
      const requiredLength = control.getError('minlength').requiredLength
      return `Input value is too short. Length ${actualLength}/${requiredLength}`;
    }

    return '';
  }
}
