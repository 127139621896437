import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


/**
 * Used to show hide toolbar and tabs. Move Fab
 */
@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  private scrolling$ = new BehaviorSubject(false);
  set = (state: boolean) => this.scrolling$.next(state);
  get = () => this.scrolling$.asObservable();
}
