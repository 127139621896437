import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { finalize, startWith } from 'rxjs/operators';
import { SOCKET_ROOM_JOIN, SOCKET_ROOM_LEAVE } from 'src/app/utils/constants';


@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(
    private socket: Socket,
  ) { }

  private socketRooms: Set<string> = new Set();
  private hooks: {[endpoint: string]: BehaviorSubject<boolean>} = {};
  private observables: {[endpoint: string]: Observable<boolean> } = {};

  private hookOf(endpoint: string) {
    if(!this.hooks[endpoint]) {
      this.hooks[endpoint] = new BehaviorSubject(true);
    }
    return this.hooks[endpoint];
  }

  /**
   *
   * @param endpoint Api endpoint to observe
   * @param socketEndpoint (optional) if the socket room is different than the endpoint
   */
  public observableOf(endpoint: string, socketEndpoint = endpoint): Observable<boolean> {
    if(!this.observables[endpoint]) {
      this.socketRooms.add(socketEndpoint);
      this.socket.emit(SOCKET_ROOM_JOIN, socketEndpoint);
      const stream: Observable<boolean> = this.socket.fromEvent(socketEndpoint);
      const hookedStream: Observable<boolean> = merge(stream, this.hookOf(endpoint));
      this.observables[endpoint] = hookedStream.pipe(
        finalize(() => {
          // console.log(`Observable finalize called for ${endpoint}`);
          this.socketRooms.delete(socketEndpoint);
          this.socket.emit(SOCKET_ROOM_LEAVE, socketEndpoint);
        }),
        startWith(true) // Emit for new subscribers
      );
    }
    return this.observables[endpoint];
  }

  /**
   * Cause an emission on the observable of the endpoint
   */
  public pingHook(endpoint: string, socketEndpoint = endpoint) {
    this.socket.emit(SOCKET_ROOM_JOIN, socketEndpoint);
    this.hookOf(endpoint).next(true);
  }

  public rejoinSocketRooms() {
    this.socketRooms.forEach(room => this.socket.emit(SOCKET_ROOM_JOIN, room));
  }
}
