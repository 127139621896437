import * as formatter from 'src/app/utils/formatter';

/**
 * Server model
 */
export class CompanyModel {

   constructor(
    public id: string,
    public externalId: string,
    public name: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(item: any): CompanyModel {
    return new CompanyModel(
      item._id || null,
      item.externalId || null,
      item.name || null,
      new Date(item.createdAt) || null,
      new Date(item.updatedAt) || null,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class CompanyViewModel extends CompanyModel {

  constructor(company: CompanyModel) {
    super(
      company.id,
      company.externalId,
      company.name,
      company.createdAt,
      company.updatedAt,
    );
  }

  /** Shortcuts to access formatter in HTML Template */
  getShortId = () => formatter.getShortId(this);
}
