import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Socket } from 'ngx-socket-io';
import Rollbar from 'rollbar';
import { BehaviorSubject, NEVER } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserModel } from 'src/app/models/user_model';
import { LocalService } from 'src/app/services/local.service';
import { environment } from 'src/environments/environment';
import { RollbarService } from './rollbar.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public authenticationState = new BehaviorSubject(false);
  private API_URL = `${environment.apiUrl}/api`;

  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    private socketService: SocketService,
    private socket: Socket,
    private localService: LocalService,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {
    this.checkAuth();
  }

  private checkAuth() {
    if (this.checkToken()) {
      this.authenticationState.next(true);
      // Configure rollbar
      this.rollbar.configure({
        payload: {
          person: {
            id: this.localService.getLocalUser()?.id,
            username: this.localService.getLocalUser()?.name,
          }
        }
      });
      // Authenticate socket
      this.authenticateSocket();
      // socket callbacks
      this.socket.on('authenticated', () => {
        // Rejoin rooms if any were present
        this.socketService.rejoinSocketRooms();
        // Reauthenticate if disconnected and connected again
        this.socket.removeAllListeners('connect'); // Clear existing listeners
        this.socket.on('connect', () => this.authenticateSocket() ); // Add new listeners
      })
      this.socket.on('unauthorized', (msg) => {
          // console.log(`socket unauthorized: ${JSON.stringify(msg.data)}`);
          this.logout();
        });
    }
  }

  private authenticateSocket = () => this.socket.emit('authenticate', { token: this.localService.getLocalToken() });

  private checkToken() {
    const token = this.localService.getLocalToken();
    const isExpired = this.helper.isTokenExpired(token);
    if (isExpired) {
      this.logout();
      return false;
    }
    return true;
  }

  confirmationResult: any;

  public async sendOTP(phone: string) {
    const appVerifier = (window as any).recaptchaVerifier;
    const auth = environment.fauth.getAuth();
    this.confirmationResult = await environment.fauth.signInWithPhoneNumber(auth, phone, appVerifier);
    this.localService.setLocalPhoneNumber(phone);
  }

  public async loginWithPhoneNumber(code: string): Promise<UserModel> {
    const firebaseUser = await this.confirmationResult.confirm(code);
    const idToken = await firebaseUser.user.getIdToken(/* forceRefresh */ true)
    const data = await this.post("/users/login/otp", { idToken: idToken }).toPromise();
    // save data and map model
    const user = UserModel.parse(data.user);
    this.localService.setLocalToken(data.token);
    this.localService.setLocalUser(user);
    this.checkAuth(); // Mark state as logged in
    return user;
  }

  public async loginWithPassword(formValue: {id: string, password: string, captcha: string}): Promise<UserModel> {
    // Avoid sending json with a field called password
    const postData = {
      id: formValue.id,
      value: formValue.password,
      captcha: formValue.captcha,
    }
    const data = await this.post("/users/login/password", postData).toPromise();
    // save data and map model
    const user = UserModel.parse(data.user);
    this.localService.setLocalToken(data.token);
    this.localService.setLocalUser(user);
    this.checkAuth(); // Mark state as logged in
    return user;
  }

  public async logout() {
    this.localService.removeLocalStorage();
    this.firebaseLogout();
    this.authenticationState.next(false);
  }

  //////////////////////////// Firebase functions ///////////////////////

  private firebaseLogout() {
    return environment.fauth.signOut(environment.fauth.getAuth());
  }

  //////////////////////////// Network functions ///////////////////////

  public get(url: string) {
    return this.http.get<any>(`${this.API_URL}${url}`)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`GET ${url} failed with response code ${status}`, e);
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public getWithOptions(url: string, options: any) {
    return this.http.get<any>(`${this.API_URL}${url}`, options)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`GET ${url} failed with response code ${status}`, e);
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public post(url: string, data: any) {
    return this.http.post<any>(`${this.API_URL}${url}`, data)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`POST ${url} failed with response code ${status}`, e);
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public delete(url: string) {
    return this.http.delete<any>(`${this.API_URL}${url}`)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`DELETE ${url} failed with response code ${status}`, e);
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public put(url: string, data: any) {
    return this.http.put<any>(`${this.API_URL}${url}`, data)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`PUT ${url} failed with response code ${status}`, e);
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public postWithOptions(url: string, data: any, options: any) {
    return this.http.post<any>(`${this.API_URL}${url}`, data, options)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`POST ${url} failed with response code ${status}`, e);
          if (status === 401) {
            this.logout();
            return NEVER;
          } else if (status === 403) {
            this.router.navigate(['/']);
            return NEVER;
          } else {
            throw e;
          }
        })
      );
  }

  public externalGetWithOptions(url: string, options: any) {
    return this.http.get<any>(url, options)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`GET ${url} failed with response code ${status}`, e);
          throw e;
        })
      );
  }

  public externalPostWithOptions(url: string, data: any, options: any) {
    return this.http.post<any>(url, data, options)
      .pipe(
        catchError(e => {
          const status = e.status;
          this.rollbar.error(`POST ${url} failed with response code ${status}`, e);
          throw e;
        })
      );
  }
}
