<h2 mat-dialog-title>Select User</h2>
<div mat-dialog-content>
  <form [formGroup]="form"
  fxLayout="column" fxLayoutAlign="space-around stretch" style="padding-top: 16px; padding-bottom: 16px;">
  <mat-form-field appearance="fill">
    <mat-label>User</mat-label>
    <mat-select formControlName="user" placeholder="Select User" #singleSelect>
      <mat-option>
        <ngx-mat-select-search
        formControlName="userFilter"
        placeholderLabel="Type name or email to search"
        noEntriesFoundLabel="No result found"
        [searching]="searching">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          <img style="height: 28px; width: 28px; vertical-align: middle; border-radius: 50%;" [src]="user.photoUrl" alt="User Avatar">
          <span style="padding-left: 8px;">{{user.name}}</span>
          <span style="color: grey;"> (ID: {{user.getShortId()}})</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls.user.invalid">{{getErrorMessage('user')}}</mat-error>
  </mat-form-field>
  </form>
</div>
<mat-card *ngIf="data.errorMessages?.length > 0" class="mat-elevation-z0 error-container">
  <mat-error *ngFor="let errorMsg of data.errorMessages">
    {{ errorMsg }}
  </mat-error>
</mat-card>
<mat-dialog-actions fxLayout="row" fxLayout="row" fxLayoutAlign="end stretch">
  <button mat-button
  [disabled]="!form.valid && !form.disabled"
  (click)="onClickCancel()">Cancel</button>
  <button mat-button
  [disabled]="!form.valid && !form.disabled"
  (click)="onClickConfirm()">Confirm</button>
</mat-dialog-actions>
