<login-view
[cachedPhoneNumber]="cachedPhoneNumber"
[loginViewUiState]="loginViewUiState"
[errorText]="errorText"
[snackbar]="snackBar"
(resetClick)="onClickReset()"
(submitPhoneClick)="onClickSubmitPhoneNumber($event)"
(submitOTPClick)="onClickSubmitOTP($event)"
(passwordLoginClick)="onClickPasswordLogin()"
(submitPasswordClick)="onClickSubmitPassword($event)"
></login-view>