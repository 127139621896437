import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { CgwaPrintComponent } from './pages/cgwa/cgwa-print/cgwa-print.component';
import { CgwaComponent } from './pages/cgwa/cgwa.component';
import { GotoComponent } from './pages/goto/goto.component';
import { HomeComponent } from './pages/home/home.component';
import { KritsnamAnalyticsComponent } from './pages/kritsnam-analytics/kritsnam-analytics.component';
import { KritsnamClientsComponent } from './pages/kritsnam-clients/kritsnam-clients.component';
import { KritsnamHomeComponent } from './pages/kritsnam-home/kritsnam-home.component';
import { KritsnamNotificationComponent } from './pages/kritsnam-notification/kritsnam-notification.component';
import { LoginComponent } from './pages/login/login.component';
import { ReportDailyPrintComponent } from './pages/report-daily/report-daily-print/report-daily-print.component';
import { ReportDailyComponent } from './pages/report-daily/report-daily.component';
import { ReportMonthlyPrintComponent } from './pages/report-monthly/report-monthly-print/report-monthly-print.component';
import { ReportMonthlyComponent } from './pages/report-monthly/report-monthly.component';
import { ReportShiftPrintComponent } from './pages/report-shift/report-shift-print/report-shift-print.component';
import { ReportShiftComponent } from './pages/report-shift/report-shift.component';
import { UserAlertsComponent } from './pages/user-alerts/user-alerts.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { UserHomeComponent } from './pages/user-home/user-home.component';
import { UserIssuesComponent } from './pages/user-issues/user-issues.component';
import { ROUTE_CGWA, ROUTE_DAILY, ROUTE_DATA, ROUTE_GOTO, ROUTE_HOME, ROUTE_KRITSNAM, ROUTE_KRITSNAM_ANALYTICS, ROUTE_KRITSNAM_CLIENTS, ROUTE_KRITSNAM_NOTIFICATIONS, ROUTE_LOGIN, ROUTE_MONTHLY, ROUTE_SHIFT, ROUTE_USER, ROUTE_USER_ALERTS, ROUTE_USER_ANALYTICS, ROUTE_USER_ISSUES, UserOrgs } from './utils/constants';

export class CustomReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseRoute === true;
  }

  //Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
     this.storedRouteHandles.set(route.routeConfig.path, handle);
  }

 //Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
     return this.storedRouteHandles.has(route.routeConfig.path);
  }

  //If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
     return this.storedRouteHandles.get(route.routeConfig.path);
  }

  //Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
     return future.routeConfig === curr.routeConfig;
  }
}

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: ROUTE_HOME },
  {
    path: ROUTE_LOGIN,
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: ROUTE_HOME,
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTE_CGWA,
    canActivateChild: [AuthGuard],
    data: {auth: {cgwa: true}},
    children: [
      {
        path: '',
        component: CgwaComponent,
      },
      {
        path: 'print',
        component: CgwaPrintComponent,
      },
    ]
  },
  {
    path: ROUTE_USER,
    canActivateChild: [AuthGuard],
    data: {auth: {cgwa: false}},
    children: [
      { path: '', redirectTo: `/${ROUTE_HOME}`, pathMatch: 'full' },
      {
        path: ':id',
        component: UserHomeComponent,
        children: [
          { path: '', redirectTo: ROUTE_USER_ANALYTICS, pathMatch: 'full' },
          {
            path: ROUTE_USER_ANALYTICS,
            component: UserDashboardComponent
          },
          {
            path: ROUTE_USER_ALERTS,
            component: UserAlertsComponent
          },
          {
            path: ROUTE_USER_ISSUES,
            component: UserIssuesComponent,
            data: {
              reuseRoute: true
            }
          },
        ]
      },
    ]
  },
  {
    path: ROUTE_DATA,
    canActivateChild: [AuthGuard],
    data: {auth: {cgwa: false}},
    children: [
      { path: '', redirectTo: `/${ROUTE_HOME}`, pathMatch: 'full' },
      {
        path: ROUTE_SHIFT,
        children: [
          {
            path: '',
            component: ReportShiftComponent
          },
          {
            path: 'print',
            component: ReportShiftPrintComponent
          },
        ]
      },
      { path: ROUTE_DAILY,
        children: [
          {
            path: '',
            component: ReportDailyComponent
          },
          {
            path: 'print',
            component: ReportDailyPrintComponent
          },
        ]
      },
      { path: ROUTE_MONTHLY,
        children: [
          {
            path: '',
            component: ReportMonthlyComponent
          },
          {
            path: 'print',
            component: ReportMonthlyPrintComponent
          },
        ]
      },
    ]
  },
  {
    path: ROUTE_KRITSNAM,
    canActivateChild: [AuthGuard],
    data: {auth: {orgs: [UserOrgs.kritsnam]}},
    children: [
      { path: '', redirectTo: `/${ROUTE_HOME}`, pathMatch: 'full' },
      {
        path: ':id',
        component: KritsnamHomeComponent,
        children:[
          { path: '', redirectTo: ROUTE_KRITSNAM_ANALYTICS, pathMatch: 'full' },
          {
            path: ROUTE_KRITSNAM_ANALYTICS,
            component: KritsnamAnalyticsComponent
          },
          {
            path: ROUTE_KRITSNAM_CLIENTS,
            component: KritsnamClientsComponent
          },
          {
            path: ROUTE_KRITSNAM_NOTIFICATIONS,
            component: KritsnamNotificationComponent
          },
        ]
      },
    ]
  },
  {
    path: ROUTE_GOTO,
    component: GotoComponent
  },
  { path: '**', redirectTo: ROUTE_HOME }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      enableTracing: false // set to true to debug
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
