import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { UserViewModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { SELF_KEYWORD, ViewUiState } from 'src/app/utils/constants';

@Component({
  selector: 'app-kritsnam-notification',
  templateUrl: './kritsnam-notification.component.html',
  styleUrls: ['./kritsnam-notification.component.scss']
})
export class KritsnamNotificationComponent extends BaseComponent implements OnInit {
  user: UserViewModel;
  user$: Subscription;
  userId: string;

  inProgressCounter = 0;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    scrollingService: ScrollingService,
    router: Router,
    butler: DataService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.parent.paramMap.get('id');
    if(this.userId === SELF_KEYWORD) {
      this.userId = this.butler.localService.getLocalUserId();
    }
  }

  ngOnInit(): void {
    this.uiState = ViewUiState.data;
  }

  public async onClickSubmit(values: {title: string, message: string, url: string}) {
    try {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        width: '400px',
        data: {
          message: `This notification will be sent to all users of the platform that have notification enabled. It represents Kritsnam, please make sure the content is appropriate!`,
          title: 'Notification Broadcast',
          confirmColor: "primary",
        }
      });
      const result = await dialogRef.afterClosed().toPromise()
      if(result) {
        this.uiState = ViewUiState.loading;
        await this.butler.userApi.sendNotificationBroadcast(values.title, values.message, values.url);
        this.snackBar = {message: `Notification broadcast sent successfully!`, action: 'Close', config: { duration: 4000 }};
        this.uiState = ViewUiState.data;
      }
    } catch (error) {
      this.errorText = `Failed to send notification broadcast. Entered data is saved locally.`;
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  public async onClickReset() {
    this.uiState = ViewUiState.data;
  }
}
