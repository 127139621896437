<div id="user-issues-page" fxFlex="1 1 auto" fxLayout="column">
    <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    </app-loading-view>

    <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}" [errorText]="errorText" (resetClick)="onClickReset()">
    </app-error-view>

    <!--<div *ngIf="uiState == ViewUiState.data" fxFlex="1 1 auto"
  fxLayout="row" fxLayoutAlign="center"
  [ngStyle.xs]="{'padding.px': 0, 'margin-top.px': -8}" [ngStyle.gt-xs]="{'padding.px': 32}">

    <mat-card fxFlex="70%" fxFlex.xl ="70%" fxFlex.lg ="85%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%"
    fxLayout="column"
    id="user-issues-view-container"
    [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}"
    [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">
      <p style="position: absolute; padding: 16px;">Loading chat...</p>
      <p class="hide-banner"></p>
    </mat-card>
  </div>-->
    <div fxFlex="1 1 auto" fxLayout="row" fxLayout="row-reverse" fxLayoutAlign="stretch flex-start" fxLayoutGap="20px" fxLayoutWrap="wrap" style="padding: 32px; justify-content: space-evenly; align-items: center;" fxLayoutWrap="wrap" fxLayoutAlign="flex-start"
        [ngStyle.xs]="{'padding.px': 0, 'margin-top.px': -8}" [ngStyle.gt-xs]="{'padding.px': 32}">

        <mat-card fxFlex="70%" fxFlex.xl="70%" style="  border-radius: 10px;" fxLayoutAlign="center" fxFlex.lg="36%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%" fxLayout="column" [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}" [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">
            <h2 style="background:#0e59c1;color:white;font: 600 28px/20px Diodrum;  margin: 0px 0px 10%;  padding: 1%;
            border: 1px solid blue;
            border-radius: 10px 10px 0px 0px;
    padding: 1%;" fxLayoutAlign="center">Contact us</h2>
            <p [ngStyle.gt-xs]="{'padding-left.px': 32}" style="font: 600 26px/20px Diodrum;  margin-bottom: 5%;"><b style="font: 600 26px/20px Diodrum;">Email : </b>support@kritsnam.com</p>
            <p [ngStyle.gt-xs]="{'padding-left.px': 32}" style="font: 600 26px/20px Diodrum;  margin-bottom: 5%;"><b style="font: 600 26px/20px Diodrum;">Phone : </b>7081000701</p>
        </mat-card>



    </div>

</div>