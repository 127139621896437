import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnApi, FilterChangedEvent, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { TableCellButtonComponent } from 'src/app/components/table-cell-button/table-cell-button.component';
import { CompanyViewModel } from 'src/app/models/company_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';
import * as formatter from 'src/app/utils/formatter';

@Component({
  selector: 'kritsnam-clients-view',
  templateUrl: './kritsnam-clients-view.component.html',
  styleUrls: ['./kritsnam-clients-view.component.scss']
})
export class KritsnamClientsViewComponent extends BaseViewComponent {
  showGrid = false;

  users: UserViewModel[];
  companies: CompanyViewModel[];

  @Input('data')
  set table(data: {users: UserViewModel[], companies: CompanyViewModel[]}) {
    this.users = data?.users;
    this.companies = data?.companies;
    if (!this.users || !this.companies) return;
    this.rowData = this.users.map((user: UserViewModel) => {
      // Get company name
      return {
        id: user.id,
        'User Id': user.externalId,
        Name: user.name,
        Phone:user.phone,
        Email:user.email,
        Org:user.org,
        'Company Id':user.companyExternalId,
        Company:user.companyName,
       // Company: user.companyExternalId ? this.companies.find(c => c.externalId === user.companyExternalId).name : '-',
        'Login Id': user.login,
        Plan: user.cgwaOnly ? 'DL-E': 'DL-P',
        Deactivated: user.deleted,
        Created: user.createdAt,
        Action: true,
      }
    })
  }

  @Output('openClick')
  openClick = new EventEmitter<string>();

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  @Output('setPasswordClick')
  setPasswordClick = new EventEmitter<UserViewModel>();

  columnDefs = [
    { field: 'Action', cellRenderer: 'buttonCell', suppressSizeToFit: true, width: 100, cellRendererParams: { icon: 'visibility', parent: this } },
    { field: 'User Id', cellStyle: { 'padding-left': '24px' } },
    { field: 'Name' },
    { field: 'Phone' },
    { field: 'Email' },
    { field: 'Plan' },
    { field: 'Company' },
    { field: 'Company Id' },
    { field: 'Org' },
    { field: 'Login Id' },
    { field: 'Deactivated' },
    { field: 'Created', valueFormatter: (params) => formatter.dateToString(params.value) },

  ];

  rowData = [];
  agGridContext = { timezone: null };
  inProgressCounter = 0;

  @ViewChild('agGrid') agGrid: AgGridAngular;
  private api: GridApi;
  private columnApi: ColumnApi;

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  public async onClickReset() {
    this.resetClick.emit(true);
  }

  gridReady(params: GridReadyEvent) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.autoSizeAll(false);
    const grid = document.getElementsByClassName('ag-center-cols-container')[0]
    const gridWidth = (grid as HTMLElement).offsetWidth;
    const screenWidth = document.body.offsetWidth;
    if (screenWidth > gridWidth) {
      this.api.sizeColumnsToFit();
    }
  }

  getComponents() {
    return {
      'buttonCell': TableCellButtonComponent,
    };
  }

  onClickRowButton(node: RowNode) {
    const userId = node.data.id;
   // this.setPasswordClick.emit(this.users.find(u => u.id === userId));
    this.openClick.emit(userId);
  }

  onRowClicked(event: any) {
   // const loanId = event.data.id;
  //  this.openClick.emit(loanId);
  }

  autoSizeAll(skipHeader: boolean) {
    var allColumnIds = [];
    this.columnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });

    this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  // Save the sorting configuration in the local storage every time the sorting options are changed.
  onSortChanged(params: any) {
    const colState = this.columnApi.getColumnState();
    const sortState = colState.filter(col => col.sort != null)
      .map(col => ({colId: col.colId, sort: col.sort, sortIndex: col.sortIndex}))
    localStorage.setItem('ag-grid-persistane-sorting', JSON.stringify(sortState));
  }

  // Save the filtering configuration in the local storage every time the filtering options are changed.
  onFilterChanged(params: FilterChangedEvent) {
    if (params.api.getFilterModel()) {
      localStorage.setItem('ag-grid-persistane-filtering', JSON.stringify(params.api.getFilterModel()));
    }
  }
  onFilterTextBoxChanged() {
    this.api.setQuickFilter(

      (document.getElementById("filter-text-box") as HTMLInputElement).value
    );
  }
  // Load filtering and sorting configuration after first row is rendered.
  onFirstDataRendered(params: any): void {
    const sortState = localStorage.getItem('ag-grid-persistane-sorting');
    const filterState = localStorage.getItem('ag-grid-persistane-filtering');
    this.api.setFilterModel(JSON.parse(filterState));
    this.columnApi.applyColumnState({ state: JSON.parse(sortState), defaultState: { sort: null }});
    this.showGrid = true;
  }
}
