<div id="kritsnam-profile-page" fxFlex="1 1 auto" fxLayout="column">
  <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
  </app-loading-view>

  <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}"
  [errorText]="errorText" (resetClick)="onClickReset()">
  </app-error-view>

  <single-column-view *ngIf="uiState == ViewUiState.data"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    <div class="header-img-card">
      <div class="header">
        <div class="avatar">
          <img src={{user.photoUrl}}>
        </div>
      </div>
    </div>
    <mat-card-content style="padding: 32px;">
      <responsive-row-view>
        <div class="key">Username</div>
        <div class="value">
          <span>{{user.name}}</span>
        </div>
      </responsive-row-view>
      <responsive-row-view>
        <div class="key">ID</div>
        <div class="value">{{user.getShortId()}}</div>
      </responsive-row-view>
      <responsive-row-view>
        <div class="key">Phone Number</div>
        <div class="value">{{user.phone}}</div>
      </responsive-row-view>
    </mat-card-content>
  </single-column-view>
</div>
