import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';

@Component({
  selector: 'user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss']
})
export class UserProfileViewComponent extends BaseViewComponent implements OnInit {

  @Input('data')
  user: UserViewModel;

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {
  }

  public async onClickReset() {
    this.resetClick.emit(true);
  }
}
