import moment from 'moment-timezone';
import * as formatter from 'src/app/utils/formatter';

/**
 * Server model
 */
export class GroupModel {

   constructor(
    public id: string,
    public externalId: string,
    public name: string,
    public companyExternalId: string,
    public aggregationFormula: string,
    public hardwareShifts: string,
    public softwareShifts: string,
    public dailySMSUpdate: string,
    public dailyNocLimit: number,
    public borewellID: string,
    public dailyConsumptionCycle: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(item: any): GroupModel {
    return new GroupModel(
      item._id || null,
      item.externalId || null,
      item.name || null,
      item.companyExternalId || null,
      item.aggregationFormula || null,
      item.hardwareShifts || null,
      item.softwareShifts || null,
      item.dailySMSUpdate || null,
      +item.dailyNocLimit || null,
      item.borewellID || null,
      item.dailyConsumptionCycle || 0,
      new Date(item.createdAt) || null,
      new Date(item.updatedAt) || null,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class GroupViewModel extends GroupModel {

  isGroup = true; // to quickly check if the target is a group in html

  constructor(device: GroupModel) {
    super(
      device.id,
      device.externalId,
      device.name,
      device.companyExternalId,
      device.aggregationFormula,
      device.hardwareShifts,
      device.softwareShifts,
      device.dailySMSUpdate,
      device.dailyNocLimit,
      device.borewellID,
      device.dailyConsumptionCycle,
      device.createdAt,
      device.updatedAt,
    )
  }

  /** Shortcuts to access formatter in HTML Template */
  getShortId = () => formatter.getShortId(this);

  getShiftTimeOffsets(): Array<{start: number, end: number}> {
    let shiftConfig: string = this.softwareShifts;
    if(!shiftConfig){
      shiftConfig = "00:00-08:00,08:00-16:00,16:00-24:00";
    }
    const parsedShifts: Array<Array<string>> = shiftConfig.split(",").map((shift: string) => shift.split("-"));
    return parsedShifts.map((s: Array<string>, index: number) => {
      const startTime = s[0].split(":");
      let endTime: (string | number)[];
      if(index === 2){
        endTime = parsedShifts[0][0].split(":");
      }else{
        endTime = parsedShifts[index + 1][0].split(":");
      }
      const start = +startTime[0] * 60 + +startTime[1];
      let end = +endTime[0] * 60 + +endTime[1];
      if(index === 2){
        const shiftStart = parsedShifts[0][0].split(":");
        const startt = moment.tz("Asia/Kolkata").startOf('day');
        const endd = startt.clone().add(shiftStart[0], 'h').add(shiftStart[1], 'm');
        var duration = moment.duration(endd.diff(startt));
        var hours = duration.asMinutes();
        end = 1440 + hours
      }
      return { start, end };
    });
  }

  /**
   * Generate the time objects for all the shifts between two days
   */
  getShiftsBetween(
    startTime: moment.Moment,
    endTime: moment.Moment
    ): Array<{start_time: moment.Moment, end_time: moment.Moment}> {
    const day = startTime.clone().startOf('day');
    const shifts: Array<{start_time: moment.Moment, end_time: moment.Moment}> = [];
    const shiftOffsets = this.getShiftTimeOffsets();
    while(day.isBefore(endTime)) {
      const dayShifts =shiftOffsets.map((value: {start: number, end: number}) => ({
        start_time: day.clone().add(value.start, 'minutes'),
        end_time: day.clone().add(value.end, 'minutes'),
      }));
      shifts.push(...dayShifts);
      day.add(1, 'day');
    }
    return shifts;
  }
}
