import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment-timezone';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
// import { ViewUiState } from 'src/app/utils/constants';
import * as crypto from 'src/app/utils/crypto';
import { environment } from 'src/environments/environment';
import { DeviceViewModel } from 'src/app/models/device_model';
import { GroupViewModel } from 'src/app/models/group_model';
import { DeviceDataPeriods, ROUTE_DAILY, ROUTE_DATA, ROUTE_MONTHLY, ROUTE_SHIFT, SELF_KEYWORD, ViewUiState } from 'src/app/utils/constants';
import { UnitsService } from 'src/app/services/units.service';
import { CompanyModel } from 'src/app/models/company_model';
@Component({
  selector: 'app-cgwa',
  templateUrl: './cgwa.component.html',
  styleUrls: ['./cgwa.component.scss']
})
export class CgwaComponent extends BaseComponent implements OnInit {
  scrollingDown: boolean = false; // Used to hide toolbar, tabs and move Fab
  title="Dhaara Live"

  userId: string;
  user: UserViewModel;
  mappings: Array<MappingViewModel>;
  selectedMapping: MappingViewModel;
  selectedDate: moment.Moment;
  bannerText: string;
  company: CompanyModel;
  selectedTarget: DeviceViewModel | GroupViewModel;
  raw: {time: Date, forward_flow: number, workhour: number}[];
  shiftwise: DatumViewModel[];
  daily: DatumViewModel;
  weekly: DatumViewModel[];

  shift: DatumViewModel[];

  inProgressCounter = 0;
  units: string = "not set yet";
  unitDetails = this.butler.localService.getUnitDetails()
  constructor(
    private route: ActivatedRoute,
    scrollingService: ScrollingService,
    router: Router,
    butler: DataService,
    private unitsService: UnitsService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.queryParams.id;
    if(!this.userId) this.userId = this.butler.localService.getLocalUserId();
    if(this.route.snapshot.queryParams.date) {
      this.selectedDate = moment(this.route.snapshot.queryParams.date);
    }
    if(!this.selectedDate?.isValid() || this.selectedDate.isAfter(moment.tz('Asia/Kolkata'))) {
      this.selectedDate = moment.tz('Asia/Kolkata').startOf('day').subtract(1, 'day');
    }
    this.handleBanner();
  }

  handleBanner() {
    // Get query params, if banner present, check that it is verified, if yes, pass it to the view.
    if(this.route.snapshot.queryParams.banner && this.route.snapshot.queryParams.bannerSignature) {
      const bannerText = this.route.snapshot.queryParams.banner;
      const bannerSignature = this.route.snapshot.queryParams.bannerSignature;
      const isVerified = crypto.verifySign(bannerText, bannerSignature, environment.bannerPubKey);
      if(isVerified) this.bannerText = bannerText;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { banner: null, bannerSignature: null },
        queryParamsHandling: 'merge'
      }); // Remove from url
    }
  }

  ngOnInit(): void {
    this.fetchData()
    this.unitsService.getUnits$().subscribe(
      (units) => {
        this.units = units
        this.unitDetails = this.butler.localService.getUnitDetails()
      }
    )
  }

  private async fetchData() {
    try {
      this.uiState = ViewUiState.loading;
      const currentUserId = this.butler.localService.getLocalUserId();
      const currentUserOrg = this.butler.localService.getUserOrg();
      const user = await this.butler.userApi.getCachedUser(this.userId);
      this.user = new UserViewModel(user, currentUserId, currentUserOrg);
      this.company = await this.butler.companyApi.getCachedCompany(this.userId);
      // Fetch the devices and groups the user can see from the server
      const mappings = await this.butler.userApi.getUserMappings(this.userId);
      this.mappings = mappings.map(m => new MappingViewModel(m));
      // If a valid device id is present in query params, select that device
      if(this.route.snapshot.queryParams.device) {
        this.selectedMapping = this.mappings.find(m => m.deviceExternalId === this.route.snapshot.queryParams.device);
      }
      // Default to the first mapping if no device is selected
      if(!this.selectedMapping) {
        this.selectedMapping = this.mappings[0];
      }
      await this.fetchStatistics();
      this.uiState = ViewUiState.data;
    } catch(error) {
      this.errorText = "Failed to fetch user, please try again.";
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  async updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        device: this.selectedMapping.deviceExternalId,
        date: this.selectedDate.format('YYYY-MM-DD')
      },
      queryParamsHandling: 'merge'
    });
  }

  private async fetchStatistics() {
    try {
      this.updateQueryParams();
      this.uiState = ViewUiState.loading;
      // Request the data for the first device from the server
      if(this.selectedMapping.deviceExternalId.startsWith('group')) {
        const group = await this.butler.groupApi.getGroup(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new GroupViewModel(group);
      } else {
        const device = await this.butler.deviceApi.getDevice(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new DeviceViewModel(device);
      }

      console.log( this.selectedTarget )
      console.log( "this.selectedTarget" )
      const dayStart = this.selectedDate.clone().startOf('day').subtract(1, 'day')
      const dayEnd = this.selectedDate.clone().startOf('day');
      const weekStart = this.selectedDate.clone().startOf('week')
      const weekEnd = this.selectedDate.clone().startOf('week').add(1, 'week');

      const monthStart = this.selectedDate.clone().startOf('month')
      const monthEnd = this.selectedDate.clone().startOf('month').add(1, 'month');
    //  const shiftwise = await this.butler.dataApi.getShiftData(this.userId, monthStart, monthEnd, this.selectedMapping.deviceExternalId);

      this.raw =  await this.butler.dataApi.getRawData(this.userId, monthStart, monthEnd, this.selectedMapping.deviceExternalId);
      console.log("before raw");
     console.log(this.raw);


      const shiftwiseResp = await this.butler.dataApi.getShiftData(this.userId, weekStart, weekEnd, this.selectedMapping.deviceExternalId);
   //   const dailyResp = await this.butler.dataApi.getDailyData(this.userId, dayStart, dayEnd, this.selectedMapping.deviceExternalId);
    //  const weeklyResp = await this.butler.dataApi.getWeeklyData(this.userId, monthStart, monthEnd, this.selectedMapping.deviceExternalId);

      this.shiftwise = DatumViewModel.sanitizeData(DeviceDataPeriods.shift, weekStart, weekEnd, shiftwiseResp, this.selectedTarget);

      this.shift = this.shiftwise.map(d => new DatumViewModel(d));
    //  this.daily = DatumViewModel.sanitizeDatum(DeviceDataPeriods.daily, dayStart, dayEnd, dailyResp[0], this.selectedTarget);
      // this.weekly = DatumViewModel.sanitizeData(DeviceDataPeriods.weekly, monthStart, monthEnd, weeklyResp, this.selectedTarget);

      this.uiState = ViewUiState.data;
    } catch(error) {
      console.error(error);
      this.errorText = "Failed to fetch device statistics, please try again.";
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  public async onClickMappingChange(mapping: MappingViewModel) {
    this.selectedMapping = mapping;
    this.fetchStatistics();
  }

  public async onClickDateChange(date: any) {
    this.selectedDate = date.value.startOf('day');
    this.fetchStatistics();
  }
  public async onClickShiftReport() {
    this.router.navigate([ROUTE_DATA, ROUTE_SHIFT], { queryParams: { id: this.userId }, queryParamsHandling: 'merge' });
  }

  public async onClickDailyReport() {
    this.router.navigate([ROUTE_DATA, ROUTE_DAILY], { queryParams: { id: this.userId }, queryParamsHandling: 'merge' });
  }

  public async onClickMonthlyReport() {
    this.router.navigate([ROUTE_DATA, ROUTE_MONTHLY], { queryParams: { id: this.userId }, queryParamsHandling: 'merge' });
  }

  public async onClickDeviceCertificate() {
    try {
      const url = await this.butler.deviceApi.getCalibrationCertificate(this.selectedTarget.externalId);
      window.open(url, "_blank");
    } catch(error) {
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  public async onClickReset() {
    this.fetchData();
  }
}
