import { Component, OnInit } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { ROUTE_LOGIN, ViewUiState } from 'src/app/utils/constants';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  // Child binding
  uiState: ViewUiState;
  errorText: string;
  snackBar: {message: string, action?: string, config?: MatSnackBarConfig}
  scrollingDown: boolean = false; // Used to hide toolbar, tabs and move Fab

  constructor(
    protected readonly scrollingService: ScrollingService,
    protected readonly router: Router,
    protected readonly butler: DataService,
  ) {
    this.uiState = ViewUiState.loading;
    this.errorText = "";
    this.snackBar = null;
    this.scrollingService.get().subscribe(s => this.scrollingDown = s);
  }

  ngOnInit(): void {
  }

  public getUserImage() {
    const user: UserModel = this.butler.localService.getLocalUser();
    return user?.photoUrl;
  }

  public getUserName() {
    const user: UserModel = this.butler.localService.getLocalUser();
    return user?.name;
  }

  public onClickLogout() {
    this.butler.backendService.logout();
    this.router.navigate([ROUTE_LOGIN]);
  }

  public onClickTheme() {
    this.butler.themeService.toggleTheme();
  }

  public onClickUnits(unit: string) {
    this.butler.unitsService.toggleUnits(unit);
  }

  public setScrolling(state: boolean) {
    this.scrollingService.set(state);
  }

}
