import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { AlertViewModel } from 'src/app/models/alert_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';

@Component({
  selector: 'user-alerts-view',
  templateUrl: './user-alerts-view.component.html',
  styleUrls: ['./user-alerts-view.component.scss']
})
export class UserAlertsViewComponent extends BaseViewComponent implements OnInit {

  @Input('userId')
  userId: string;

  @Input('user')
  user: UserViewModel;

  @Input('mappings')
  mappings: Array<MappingViewModel>;

  @Input('selectedMapping')
  selectedMapping: MappingViewModel;

  @Input('data')
  data: {users: UserViewModel[], alerts: AlertViewModel[]};

  @Input('bannerText')
  bannerText: string;

  @Input('inProgressCounter')
  inProgressCounter: number;

  @Output('mappingChangeClick')
  mappingChangeClick = new EventEmitter<MappingViewModel>();

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  @Output('newAlertClick')
  newAlertClick = new EventEmitter<boolean>();

  @Output('editAlertClick')
  editAlertClick = new EventEmitter<AlertViewModel>();

  @Output('deleteAlertClick')
  deleteAlertClick = new EventEmitter<AlertViewModel>();

  @Output('testAlertClick')
  testAlertClick = new EventEmitter<AlertViewModel>();

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {
  }

  async onClickMappingChange(mapping: MappingViewModel) {
    this.mappingChangeClick.emit(mapping);
  }

  public async onClickReset() {
    this.resetClick.emit(true);
  }

  public async onClickFab() {
    this.newAlertClick.emit(true);
  }

  public async onClickEditAlert(alert: AlertViewModel) {
    this.editAlertClick.emit(alert);
  }

  public async onClickDeleteAlert(alert: AlertViewModel) {
    this.deleteAlertClick.emit(alert);
  }

  public async onClickTestAlert(alert: AlertViewModel) {
    this.testAlertClick.emit(alert);
  }

  formatPhone(phone: string): string {
    const fullPhone = '+91' + phone;
    const user = this.data.users.find(u => u.phone === fullPhone);
    const name = user ? user.name : 'Unknown';
    return `${name} (${fullPhone})`;
  }

  formatFcm(fcm: string): string {
    const user = this.data.users.find(u => u.id === fcm);
    return user.name;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
