
/**
 * Server model
 */
export class MappingModel {

   constructor(
    public name: string,
    public userExternalId: string,
    public deviceExternalId: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(item: any): MappingModel {
    return new MappingModel(
      item.name || null,
      item.userExternalId || null,
      item.deviceExternalId || null,
      new Date(item.createdAt) || null,
      new Date(item.updatedAt) || null,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class MappingViewModel extends MappingModel {

  constructor(mapping: MappingModel) {
    super(
      mapping.name,
      mapping.userExternalId,
      mapping.deviceExternalId,
      mapping.createdAt,
      mapping.updatedAt,
      )
  }
}
