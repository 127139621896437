<mat-toolbar class="mat-elevation-z5" color="primary">
    <button *ngIf="showBackButton" mat-icon-button style="margin-left: 8px;" (click)="onClickBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
    <img routerLink="/" class="toolbar-logo" style="max-height: 100%; padding: 0px 8px 0px 8px; cursor: pointer;" />
    <span routerLink="/" style="height: 100%; display: flex; align-items: center; overflow: hidden; cursor: pointer;">
    {{ toolbarTitle }}
  </span>
    <span class="spacer"></span>
    <button mat-button [matMenuTriggerFor]="userMenu">
    <img mat-card-avatar [src]="userImg" alt="User Avatar">
    <span fxHide.xs>{{ userName }}</span>
  </button>
    <mat-menu #userMenu="matMenu" yPosition="above" xPosition="before">
        <button mat-menu-item (click)="onClickLogout()">
      <mat-icon>person_remove</mat-icon>
      <span>Logout</span>
    </button>
        <button mat-menu-item (click)="onClickTheme()">
      <mat-icon>dark_mode</mat-icon>
      <span>Toggle Dark Theme</span>
    </button>
        <button mat-menu-item [matMenuTriggerFor]="units">
      <mat-icon>swap_horizontal_circle</mat-icon>
      <span>Toggle Units</span>
    </button>
    </mat-menu>
    <mat-menu #units="matMenu">
        <button mat-menu-item (click)="onClickToggleUnits('CubicMeters')">
      <span>CubicMeters</span>
    </button>
        <button mat-menu-item (click)="onClickToggleUnits('Gallons')">
      <span>Gallons</span>
    </button>
        <button mat-menu-item (click)="onClickToggleUnits('Liters')">
      <span>Liters</span>
    </button>
        <button mat-menu-item (click)="onClickToggleUnits('Litershr')">
      <span>Liters/Hr</span>
    </button>
    </mat-menu>
</mat-toolbar>