<div  fxFlex="1 1 auto" fxLayout="column">
  <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
  </app-loading-view>

  <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}"
  [errorText]="errorText" (resetClick)="onClickReset()">
  </app-error-view>

  <div fxLayout="row" fxLayoutAlign="center" *ngIf="uiState == ViewUiState.data"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    <mat-card fxFlex="70%" fxFlex.xl ="70%" fxFlex.lg ="85%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%"
    [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}"
    [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">

      <div fxLayout="row wrap">
        <h2 [ngStyle.xs]="{'padding.px': 8, 'margin.px': 0}" [ngStyle.gt-xs]="{'padding.px': 16, 'margin.px': 0}">
          <span>Shift-wise Report</span>
        </h2>
        <span class="spacer"></span>
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button *ngIf="!mobileView" mat-button (click)="onClickPrint()">
            <mat-icon style="padding: 8px;">print</mat-icon>
            <span style="padding-right: 14px;">Print</span>
          </button>
          <mat-form-field appearance="standard" style="padding: 8px; width: 150px; margin-bottom: -1.25em">
            <mat-label>Device</mat-label>
            <mat-select [value]="selectedMapping" (valueChange)="onClickMappingChange($event)">
              <mat-option *ngFor="let mapping of mappings; index as i" [value]="mapping"  [matTooltip]="mapping.name">
                {{ mapping.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="standard" style="padding: 8px; width: 150px; padding-right: 16px; margin-bottom: -1.25em;">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="datePicker"
            [value]="selectedDate" [max]="today" (dateChange)="onClickDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <mat-error fxLayout="row" fxLayoutAlign="start center" *ngIf="bannerText" class="error-container" style="border-radius: 4px; padding: 0px 16px 0px 16px;">
        <span>{{ bannerText }}</span>
        <span class="spacer"></span>
        <button mat-icon-button color="warn" (click)="bannerText = null"><mat-icon>close</mat-icon></button>
      </mat-error>

      <mat-card-content
        [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}"
        [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 0, 'padding-bottom.px': 16, 'padding-left.px': 0}">
        <div fxLayout="row wrap"
        [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}"
        [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
          <mat-card
          *ngFor="let aggregate of shiftAggregates; let i = index"
          class="mat-elevation-z0 outline" style="border-radius: 8px;"
          fxFlex="30%" fxFlex.lt-md="100%" fxFlex
          [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}"
          [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
            <div fxLayout="row wrap">
              <h4 style="margin: 0px; display: inline;">Shift {{i+1}} Aggregate</h4>
              <span class="spacer"></span>
              <mat-card-subtitle style="margin: 0px; display: inline;">
                {{ aggregate.timing }}
              </mat-card-subtitle>
              </div>
            <hr>
            <div fxLayout="row wrap">
              <div fxLayout="column" fxFlex="50%" fxFlex>
                <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Total Extraction</h3>
                  <h2>
                    {{ aggregate.value.net_volume * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                  </h2>
                </div>
                <div class="spacer"></div>
                <div *ngIf="selectedTarget.showWaterRate" style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Water Bill</h3>
                  <p style="font-size: 18px">
                    {{ aggregate.value.water_bill | currency:'INR':'₹ ':'1.0' }}
                  </p>
                </div>
                <div *ngIf="selectedTarget.showElectricityRate" style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Electricity Bill</h3>
                  <p style="font-size: 18px">
                    {{ aggregate.value.electricity_bill | currency:'INR':'₹ ':'1.0' }}
                  </p>
                </div>
              </div>
              <div fxLayout="column" fxFlex="50%" fxFlex>
                <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Working Time</h3>
                  <p style="font-size: 18px">
                    {{ aggregate.value.getWorkHourString() }}
                  </p>
                </div>
                <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Flow Rate</h3>
                  <p style="font-size: 18px">
                    {{ aggregate.value.flowrate * unitDetails.volume/unitDetails.time | number: '1.2-2' }} {{ unitDetails.symbols.flowrate }}
                  </p>
                </div>
                <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Forward Flow</h3>
                  <p style="font-size: 18px">
                    {{ aggregate.value.forward_flow * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                  </p>
                </div>
                <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Reverse Flow</h3>
                  <p style="font-size: 18px">
                    {{ aggregate.value.reverse_flow * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                  </p>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxLayout="row"
        [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}"
        [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
          <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;"
          fxFlex
          [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}"
          [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
            <div fxLayout="row wrap">
              <h4 style="margin: 0px; display: inline;">Weekly Shift-wise Consumption</h4>
              <span class="spacer"></span>
              <mat-card-subtitle style="margin: 0px; display: inline;">
                {{ selectedDate.clone().startOf('week') | date: 'EEEE, d MMM y' }} to {{ selectedDate.clone().startOf('week').add(1, 'week') | date: 'EEEE, d MMM y' }}
              </mat-card-subtitle>
            </div>
            <hr>
            <div fxLayout="row" fxLayoutAlign="space-around center">
              <div class="ngx-chart-wrapper" style="padding: 16px 0px;" fxLayout="column"
              fxFlex="99%" fxLayoutAlign="start center">
                <ngx-charts-bar-vertical-2d
                [scheme]="shiftChartColorScheme"
                [results]="shiftChartData"
                [showGridLines]="true"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="true"
                [legendTitle]="' '"
                [legendPosition]="'below'"
                [rotateXAxisTicks]="false"
                [roundEdges]="false">
                </ngx-charts-bar-vertical-2d>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxLayout="row"
        [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}"
        [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
          <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;"
          fxFlex
          [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16, 'padding-top.px': 8}"
          [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16, 'padding-top.px': 8}">
            <div fxLayout="row wrap" style="align-items: center;">
              <h4 style="margin: 0px; display: inline;">Monthly Shift-wise Data</h4>
              <div class="spacer"></div>
              <button mat-button (click)="onClickDownload()">
                Download
                <mat-icon>download</mat-icon>
              </button>
            </div>
            <hr>
            <div fxLayout="row" fxLayoutAlign="space-around center">
              <ag-grid-angular *ngIf="shiftTableData.length !== 0"
              id="grid"
              #agGrid
              style="width: 100%;"
              class="ag-theme-material"
              [rowData]="shiftTableData"
              [columnDefs]="columnDefs"
              [context]="agGridContext"
              [suppressCellSelection] ="true"
              [pagination]="true"
              [paginationPageSize]="10"
              [defaultColDef]="{
                resizable: true,
                sortable: true,
                filter: true
              }"
              domLayout='autoHeight'
              (gridReady)="gridReady($event)">
              </ag-grid-angular>
            </div>
          </mat-card>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
