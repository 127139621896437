import moment from 'moment-timezone';
import { FormArray, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const ROUTE_LOGIN = 'login';
export const ROUTE_HOME = 'home';
export const ROUTE_USER = 'users';
export const ROUTE_USER_ANALYTICS = 'analytics';
export const ROUTE_USER_ALERTS = 'alerts';
export const ROUTE_USER_ISSUES = 'issues';
export const ROUTE_KRITSNAM = 'kritsnam';
export const ROUTE_KRITSNAM_ANALYTICS = 'analytics';
export const ROUTE_KRITSNAM_NOTIFICATIONS = 'notifications';
export const ROUTE_KRITSNAM_CLIENTS = 'clients';
export const ROUTE_DATA = 'data';
export const ROUTE_SHIFT = 'shift';
export const ROUTE_DAILY = 'daily';
export const ROUTE_MONTHLY = 'monthly';
export const ROUTE_GOTO = 'goto';
export const ROUTE_CGWA = 'cgwa';

export const TOKEN_KEY = 'access_token';
export const USER_KEY = 'user_data';
export const PHONE_KEY = 'login_phone_key';
export const DARK_THEME_KEY = 'dark_theme_key';
export const NOTIFICATION_DND_KEY = 'notification_dnd_key';

export const SOCKET_ROOM_JOIN = 'join';
export const SOCKET_ROOM_LEAVE = 'leave';

export const SELF_KEYWORD = 'self';

export enum ViewUiState {
  data,
  loading,
  error
}

export enum UserOrgs {
  kritsnam = 'Kritsnam',
  client = 'Client',
}

export enum DeviceDataPeriods {
  shift = 'Shift',
  daily = 'Daily',
  weekly = 'Weekly',
  monthly = 'Monthly',
}

export enum AlertPriority {
  info = 'Info',
  error = 'Error',
  urgent = 'Urgent',
  emergency = 'Emergency',
}

export enum AlertTarget {
  forward_flow = 'Forward Flow',
  reverse_flow = 'Reverse Flow',
  net_volume = 'Net Volume',
  flowrate = 'Flowrate',
  workhour = 'Workhour',
  electricity_bill = 'Electricity Bill',
  water_bill = 'Water Bill',
}

export const defaultDateOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
}

export const GLOBAL_TIMEZONE = 'Asia/Kolkata';

export const EMPTY_TELEMETRY: any = {
  forward_flow: 0,
  reverse_flow: 0,
  workhour: 0
};

export const EMPTY_STATISTICS: any = {
  forward_flow: 0,
  reverse_flow: 0,
  net_volume: 0,
  flowrate: 0,
  workhour: 0,
  electricity_bill: 0,
  water_bill: 0,
};

export const EPOCH = moment.tz('1970-01-01T00:00:00Z', 'Asia/Kolkata');

/**
 * Show errors instantly
 * Error when invalid control is dirty, touched, or submitted.
 *
*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

/**
 * Helps check total of investments and revenues
 */
export function addUpToValidator(expectedTotal: number) {
  return (formArray: FormArray): any => {
    if(expectedTotal === undefined || expectedTotal === null) return null; // Validators.required can be added separately
    if(formArray.length == 0) return null; // Validators.required can be added separately
    const totalBalance = formArray.controls.map((i: FormGroup) => i.controls.val.value).reduce((a, b) => a + b, 0);
    if(totalBalance !== expectedTotal) {
      return {
        total: {
          expectedTotal: expectedTotal,
          actualTotal: totalBalance
        }
      }
    }
    return null;
  }
}

export function getCssVariable(name: string) {
  var style = getComputedStyle(document.body)
  return style.getPropertyValue(name)
}
