import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-goto',
  templateUrl: './goto.component.html',
  styleUrls: ['./goto.component.scss']
})
export class GotoComponent {

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    const gotoUrl = this.route.snapshot.queryParams.goto;
    if(gotoUrl) {
      window.location.replace(gotoUrl);
    } else {
      this.router.navigate(['/']);
    }
  }
}
