import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { NotificationPermissionPopupComponent, NotificationPermissionPopupResult } from './components/notification-permission-popup/notification-permission-popup.component';
import { ROUTE_LOGIN } from './utils/constants';
import { environment } from 'src/environments/environment';
import { PosthogService } from './services/posthog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public butler: DataService,
    public router: Router,
    public dialog: MatDialog,
    public posthog: PosthogService,
    updates: SwUpdate,
    push: SwPush,
  ) {
    const authenticationState$ = this.butler.backendService.authenticationState;
    this.keepUserDataUpdated(authenticationState$);
    this.logoutOnUnauthenticated(authenticationState$);
    this.firebaseCloudMessaging(authenticationState$);
    this.firebaseAnalytics();
    this.posthogInitialize();

    // If service worker if an update is available, reload the page, avoid user confusion
    updates.available.subscribe(_ => updates.activateUpdate().then(() => {
      console.log('reload for update');
      document.location.reload();
    }));

    // Handle notifications
    push.messages.subscribe(msg => console.log('push message', msg));
    push.notificationClicks.subscribe(click => console.log('notification click', click));
  }

  /**
   * On getting authenticated, keep local user synced
   */
   keepUserDataUpdated(authenticationState$: Observable<boolean>) {
    authenticationState$.pipe(
      filter((authenticated: boolean) => authenticated),
      take(1)
      ) // First true value
      .subscribe(authenticated => {
        this.butler.userApi.keepLocalUserUpdated();
      });
  }

  /**
   * Check if user become unauthenticated
   */
  logoutOnUnauthenticated(authenticationState$: Observable<boolean>) {
    authenticationState$.pipe(skip(1)) // Skip first value because initial check is handled by auth guards
    .subscribe(authenticated => {
      if (!authenticated) {
        // not logged in so redirect to login page with the return url
        this.router.navigate([ROUTE_LOGIN], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
      }
    });
  }

  async firebaseCloudMessaging(authenticationState$: Observable<boolean>) {
    // Wait for user to be authenticated, first true value
    await authenticationState$.pipe(filter((authenticated: boolean) => authenticated), take(1)).toPromise();
    const permissionGranted = Notification.permission === "granted";
    const notificationDnd = this.butler.localService.getNotificationDnd();
    const isPrint = window.location.pathname.includes('print');
    if (permissionGranted) {
      this.butler.userApi.updateFcmToken();
    } else if(!notificationDnd && !isPrint) {
      // Show dialog with option to switch on DND
      const dialogRef = this.dialog.open(NotificationPermissionPopupComponent, {
        width: '400px',
        data: {
          title: 'Notification Permission',
          message: 'Please grant permission to show notification when alert limits are crossed.',
          confirmColor: 'primary',
        }
      });
      const result: NotificationPermissionPopupResult = await dialogRef.afterClosed().toPromise();
      console.log(result)
      if(result?.dnd) this.butler.localService.setNotificationDnd(true);
      if(result?.accept) {
        const result = await Notification.requestPermission();
        if(result === "granted") this.butler.userApi.updateFcmToken();
      }
    }
  }

  async firebaseAnalytics() {
    const analytics = environment.fanalytics.getAnalytics();
    this.router.events.subscribe((event) => {
      environment.fanalytics.logEvent(analytics, 'route_event', event);
    });
  }

  async posthogInitialize() {
    const user = this.butler.localService.getLocalUser();
    if (user) this.posthog.setPostHogUserId(user.id, user.name)
  }
}
