import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { SELF_KEYWORD, ViewUiState } from 'src/app/utils/constants';

@Component({
  selector: 'app-kritsnam-analytics',
  templateUrl: './kritsnam-analytics.component.html',
  styleUrls: ['./kritsnam-analytics.component.scss']
})
export class KritsnamAnalyticsComponent extends BaseComponent implements OnInit {
  userId: string;

  companies: {name: string, value: number};
  users: {name: string, value: number};
  devices: {name: string, value: number};
  inProgressCounter = 0;

  constructor(
    private route: ActivatedRoute,
    scrollingService: ScrollingService,
    router: Router,
    butler: DataService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.parent.paramMap.get('id');
    if(this.userId === SELF_KEYWORD) {
      this.userId = this.butler.localService.getLocalUserId();
    }
  }

  ngOnInit(): void {
    this.fetchData()
  }

  private async fetchData() {
    try {
      this.uiState = ViewUiState.loading;
      const data = await this.butler.userApi.getPlatformAnalytics();
      this.companies = { name: 'Number of companies', value: data.companies };
      this.users = { name: 'Number of users', value: data.users };
      this.devices = { name: 'Number of devices', value: data.devices };
      this.uiState = ViewUiState.data;
    } catch(error) {
      this.errorText = "Failed to fetch user, please try again.";
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    };
  }

  public async onClickReset() {
    this.fetchData();
  }
}
