<kritsnam-analytics-view
style="width: 100%; height: 100%"
[companies]="companies"
[users]="users"
[devices]="devices"
[uiState]="uiState"
[errorText]="errorText"
[snackbar]="snackBar"
(resetClick)="onClickReset()"
></kritsnam-analytics-view>
