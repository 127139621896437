import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from './local.service';

@Injectable({
    providedIn: 'root'
})
export class UnitsService {
    private units$: BehaviorSubject<string>;

    constructor(
        private localService: LocalService,
    ) {
        const units = this.localService.getUnits();
        this.units$ = new BehaviorSubject<string>(units);
    }

    public getUnits$(){
        return this.units$;
    }

    public toggleUnits(unit: string) {
        this.localService.setUnits(unit);
        //this.units$.next(units);
        location.reload();
    }
}