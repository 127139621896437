import { Component } from '@angular/core';

@Component({
  selector: 'app-table-cell-button',
  templateUrl: './table-cell-button.component.html',
  styleUrls: ['./table-cell-button.component.scss'],
})
export class TableCellButtonComponent {
  private params: any;
  icon: string;
  enabled: boolean;

  agInit(params: any): void {
    this.params = params;
    this.icon = params.icon;
    // If a value is passed use it, or just show the button
    this.enabled = params.value !== undefined ? params.value : true;
  }

  btnClickedHandler(event: any) {
    event.stopPropagation()
    this.params.parent.onClickRowButton(this.params.node);
  }
}
