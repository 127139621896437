import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-user-select-popup',
  templateUrl: './user-select-popup.component.html',
  styleUrls: ['./user-select-popup.component.scss']
})
export class UserSelectPopupComponent implements OnInit, OnDestroy {

  // User autocomplete
  public filteredUsers: ReplaySubject<UserModel[]> = new ReplaySubject<UserModel[]>(1);
  public searching = false;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  form: FormGroup;

  constructor(
    private readonly butler: DataService,
    private dialogRef: MatDialogRef<UserSelectPopupComponent>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      user: [
        '', [Validators.required]
      ],
      userFilter: [ '' ]
    });
    // listen for search field value changes
    this.form.controls.userFilter.valueChanges
      .pipe(
        filter(search => !!search),
        filter(search => search?.length > 3),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(500),
        switchMap(async (search): Promise<UserModel[]> => {
          try {
            const users = await this.butler.userApi.searchUsers(search);
            return users;
          } catch (error) {
            this.data.errorMessages = [error.message]
            return [];
          }
        }),
        takeUntil(this._onDestroy),
      )
      .subscribe(filteredUsers => {
        this.searching = false;
        this.filteredUsers.next(filteredUsers);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public async onClickCancel() {
    this.dialogRef.close();
  }

  public async onClickConfirm() {
    this.dialogRef.close(this.form.controls.user.value);
  }

  public getErrorMessage(key: string) {
    if (this.form.controls[key].hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }
}
