import { Injectable } from '@angular/core';
import { CompanyApi } from './api/company.api';
import { DataApi } from './api/data.api';
import { DeviceApi } from './api/device.api';
import { GroupApi } from './api/group.api';
import { UserApi } from './api/user.api';
import { BackendService } from './backend.service';
import { LocalService } from './local.service';
import { ThemeService } from './theme.service';
import { UnitsService } from './units.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public backendService: BackendService,
    public localService: LocalService,
    public themeService: ThemeService,
    public companyApi: CompanyApi,
    public userApi: UserApi,
    public dataApi: DataApi,
    public deviceApi: DeviceApi,
    public groupApi: GroupApi,
    public unitsService: UnitsService,
  ) {
  }
}
