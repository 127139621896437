import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface NotificationPermissionPopupOptions {
  title: string,
  message: string,
  confirmColor: string,
}

export interface NotificationPermissionPopupResult {
  dnd: boolean,
  accept: boolean,
}

@Component({
  selector: 'app-notification-permission-popup',
  templateUrl: './notification-permission-popup.component.html',
  styleUrls: ['./notification-permission-popup.component.scss']
})
export class NotificationPermissionPopupComponent implements OnInit {
  dnd: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<NotificationPermissionPopupComponent, NotificationPermissionPopupResult>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationPermissionPopupOptions
  ) { }

  ngOnInit(): void {
  }

  onClickCancel() {
    this.dialogRef.close({dnd: this.dnd, accept: false});
  }

  onClickConfirm() {
    this.dialogRef.close({dnd: this.dnd, accept: true});
  }
}
