import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import moment from 'moment-timezone';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';
import * as formatter from "src/app/utils/formatter";
import * as exporter from 'src/app/utils/exporter';
import { ViewUiState } from 'src/app/utils/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DeviceViewModel } from 'src/app/models/device_model';
import { GroupViewModel } from 'src/app/models/group_model';

@Component({
  selector: 'app-report-shift-print',
  templateUrl: './report-shift-print.component.html',
  styleUrls: ['./report-shift-print.component.scss']
})
export class ReportShiftPrintComponent implements OnInit {

  ViewUiState = ViewUiState; // For access in HTML

  uiState: ViewUiState;
  errorText: string;

  userId: string;
  user: UserViewModel;
  mappings: Array<MappingViewModel>;
  selectedMapping: MappingViewModel;
  selectedDate: moment.Moment;
  selectedTarget: DeviceViewModel | GroupViewModel;

  shift: DatumViewModel[];

  today = new Date();
  shiftChartData: Array<{name: string, series: Array<{name: string, value: number}>}> = [];
  shiftChartColorScheme = { domain: ['#31C3DF', '#FFD47A', '#DC189C'] };
  shiftAggregates: Array<{timing: string, value: DatumViewModel}>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private butler: DataService,
  ) {
    this.userId = this.route.snapshot.queryParams.id;
    if(!this.userId) this.userId = this.butler.localService.getLocalUserId();
    if(this.route.snapshot.queryParams.date) {
      this.selectedDate = moment(this.route.snapshot.queryParams.date);
    }
    if(!this.selectedDate?.isValid() || this.selectedDate.isAfter(moment.tz('Asia/Kolkata'))) {
      this.selectedDate = moment.tz('Asia/Kolkata').startOf('day').subtract(1, 'day');
    }
  }

  ngOnInit(): void {
    this.fetchData()
  }

  private async fetchData() {
    try {
      this.uiState = ViewUiState.loading;
      const currentUserId = this.butler.localService.getLocalUserId();
      const currentUserOrg = this.butler.localService.getUserOrg();
      const user = await this.butler.userApi.getCachedUser(this.userId);
      this.user = new UserViewModel(user, currentUserId, currentUserOrg);
      // Fetch the devices and groups the user can see from the server
      const mappings = await this.butler.userApi.getUserMappings(this.userId);
      this.mappings = mappings.map(m => new MappingViewModel(m));
      // If a valid device id is present in query params, select that device
      if(this.route.snapshot.queryParams.device) {
        this.selectedMapping = this.mappings.find(m => m.deviceExternalId === this.route.snapshot.queryParams.device);
      }
      // Default to the first mapping if no device is selected
      if(!this.selectedMapping) {
        this.selectedMapping = this.mappings[0];
      }
      await this.fetchStatistics();
      await this.formatStatistics();
      this.uiState = ViewUiState.data;
      // setTimeout without a delay helps do something after dom finishes rendering
      setTimeout(() => window.print(), 1000);
      window.onafterprint = window.close;
    } catch(error) {
      this.errorText = "Failed to fetch user, please try again.";
      this.uiState = ViewUiState.error;
    }
  }

  private async fetchStatistics() {
    try {
      this.uiState = ViewUiState.loading;
      // Request the data for the first device from the server
      if(this.selectedMapping.deviceExternalId.startsWith('group')) {
        const group = await this.butler.groupApi.getGroup(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new GroupViewModel(group);
      } else {
        const device = await this.butler.deviceApi.getDevice(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new DeviceViewModel(device);
      }
      const monthStart = this.selectedDate.clone().startOf('month')
      const monthEnd = this.selectedDate.clone().startOf('month').add(1, 'month');
      const shiftwise = await this.butler.dataApi.getShiftData(this.userId, monthStart, monthEnd, this.selectedMapping.deviceExternalId);
      this.shift = shiftwise.map(d => new DatumViewModel(d));
      this.uiState = ViewUiState.data;
    } catch(error) {
      console.error(error);
      this.errorText = "Failed to fetch device statistics, please try again.";
      this.uiState = ViewUiState.error;
    }
  }

  async formatStatistics() {
    // Calculate the aggregates for each shift
    this.shiftAggregates = [];
    for(let s of this.shift) {
      const timing = moment(s.end_time).format('hh:mm a');
      const timingAggregate = this.shiftAggregates.find(a => a.timing === timing);
      timingAggregate ? timingAggregate.value.add(s): this.shiftAggregates.push({timing: timing, value: s.clone()});
    }
    // Show the chart for the shift-wise report
    this.shiftChartData = [];
    for(let s of this.shift) {
      const t = moment(s.end_time);
      const d = moment(s.end_time).startOf('day');
      // Skip all entries out of the current week
      if(t.isBefore(this.selectedDate.clone().startOf('week')) ||
         t.isAfter(this.selectedDate.clone().startOf('week').add(1, 'week'))
        ) continue;
      // Midnight reading is counted in previous day
      if(t.isSame(d)) d.subtract(1, 'day');
      const label = `${d.format('DD/MM')} to ${d.clone().add(1, 'day').format('DD/MM')}`
      const dataOfDay = this.shiftChartData.find(d => d.name === label);
      if(!dataOfDay) {
        this.shiftChartData.push({
          name: label,
          series: [{
            name: moment(s.end_time).format('hh:mm a'),
            value: s.net_volume
          }]
        });
      } else {
        dataOfDay.series.push({
          name: moment(s.end_time).format('hh:mm a'),
          value: s.net_volume
        });
      }
    }
  }
}
