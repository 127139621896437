<user-dashboard-view
id="user-dashboard-page"
style="width: 100%; height: 100%"
[user]="user"
[company]="company"
[mappings]="mappings"
[selectedMapping]="selectedMapping"
[selectedDate]="selectedDate"
[selectedTarget]="selectedTarget"
[raw]="raw"
[shiftwise]="shiftwise"
[daily]="daily"
[weekly]="weekly"
[inProgressCounter]="inProgressCounter"
[uiState]="uiState"
[errorText]="errorText"
[snackbar]="snackBar"
[units]="units"
[unitDetails]="unitDetails"
(mappingChangeClick)="onClickMappingChange($event)"
(dateChangeClick)="onClickDateChange($event)"
(shiftReportClick)="onClickShiftReport()"
(dailyReportClick)="onClickDailyReport()"
(monthlyReportClick)="onClickMonthlyReport()"
(deviceCertificateClick)="onClickDeviceCertificate()"
(resetClick)="onClickReset()"
></user-dashboard-view>
