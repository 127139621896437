import moment from "moment";
import { defaultDateOptions, GLOBAL_TIMEZONE } from "./constants";

/**
 * Don't ask me how this works https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 */
export function numberWithCommas(x: number): string {
  if(x === null || x === undefined) return;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Convert camel case to title case
 * https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text
 * @param text camel case string
 */
export function getTitleCase(text: string) {
  if(!text) return;
  var result = text.replace( /([A-Z])/g, " $1" );
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult
}

export function dateToString(date: Date): string {
  if(!date) return;
  return date.toLocaleString('en-US',
  {
    ...defaultDateOptions,
    timeZone: GLOBAL_TIMEZONE
  });
}

export function getShortId(model: any) {
  if(!model) return;
  return `#${model.id.substr(-10)}`;
}

export function formatPhoneNumber(number: number) {
  if(!number) return;
  return number? `+91 ${number}`: number;
}

export function getFileDisplayName(fileKey: string) {
  if(!fileKey) return;
  const parts = fileKey.split('-');
  if(parts.length === 1) return fileKey;
  if(parts.length > 1 && parts.length < 4) throw new Error(`Invalid file key ${fileKey}`);
  return parts.slice(3).join('-');
}

export function parseDateString(dateString: string) {
  return new Date(dateString);
}

export function getWorkHourString(seconds: number) {
  const hours = ~~(moment.duration(seconds, 's').asHours());
  const minutes = moment.tz('Asia/Kolkata').startOf('day').seconds(seconds).format('mm [Mins]');
  const hourString = hours < 2 ? `${hours} Hr`: `${hours} Hrs`;
  return hourString + " " + minutes;
}

export function formatAlertDescriptionMessage(
  deviceExternalId: string,
  priority: string,
  target: string,
  period: string,
  threshold: number,
  emails: any[],
  phones: any[],
  fcms: any[]
): string {
  priority = priority || 'PRIORITY'; // Default values
  target = target || 'TARGET'; // Default values
  period = period || 'PERIOD'; // Default values
  const thresholdString = threshold ? numberWithCommas(threshold) : 'THRESHOLD'; // Default values
  return `${priority} alert for device ${deviceExternalId} when ${period} ${target} crosses ${thresholdString}. On alert hit, notify ${emails.length} emails, ${phones.length} phone by sms, ${fcms.length} users by app notification.`
}

export function dateIsSameOrAfter(date: Date) {
  return moment
    .tz(date, GLOBAL_TIMEZONE)
    .isSameOrAfter(moment.tz(GLOBAL_TIMEZONE));
}
