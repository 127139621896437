<div id="kritsnam-home-page" class="page" fxLayout="column">
  <app-toolbar-view
  class="app-toolbar" [class.poof]="scrollingDown"
  [userImg]="getUserImage()"
  [userName]="getUserName()"
  (themeClick)="onClickTheme()"
  (logoutClick)="onClickLogout()">
  </app-toolbar-view>

  <div fxFlex="1 1 auto"
  style="padding-top: 64px; overflow-y: auto;" scrollDirection (scrolledUp)="setScrolling(false)" (scrolledDown)="setScrolling(true)">
    <router-outlet></router-outlet>
  </div>

  <nav mat-tab-nav-bar mat-stretch-tabs
  class="app-tabs" [class.poof]="scrollingDown"
  headerPosition="below" mat-align-tabs="center"
  color="accent" backgroundColor="primary">
    <a mat-tab-link
    *ngFor="let link of navLinks"
    [routerLink]="link.link"
    queryParamsHandling="preserve"
    id={{link.label}}
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
        <mat-icon class="tab-icon">{{ link.icon }}</mat-icon>
        <span *ngIf="!mobileView">{{ link.label }}</span>
    </a>
  </nav>
</div>
