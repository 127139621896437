import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PosthogService {

  constructor(
  ) { }

  public initPostHog = () => {
    if (environment.posthogId)
      posthog.init(environment.posthogId, {
        api_host: 'https://app.posthog.com',
      });
  };

  public setPostHogUserId = (userId: string, name: string) => {
    posthog.identify(userId, { name: name });
  };

  public sendPostHogData = (
    eventType: string,
    eventProperties?: posthog.Properties
  ) => {
    posthog.capture(eventType, eventProperties);
  };
}
