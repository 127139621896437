import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgFormsManager } from '@ngneat/forms-manager';
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertViewModel } from 'src/app/models/alert_model';
import { UserViewModel } from 'src/app/models/user_model';
import { AlertPriority, AlertTarget, DeviceDataPeriods } from 'src/app/utils/constants';
import * as formatter from 'src/app/utils/formatter';

export interface AlertEditPopupOptions {
  deviceExternalId: string,
  allUsers: UserViewModel[],
  allAlerts: AlertViewModel[],
  alert: AlertViewModel,
}

export interface AlertEditPopupResult {
  priority: AlertPriority,
  target: AlertTarget,
  period: DeviceDataPeriods,
  threshold: number,
  emails: string[],
  phones: string[],
  fcms: string[],
}

const ALERT_FORM = 'ALERT_FORM';

@Component({
  selector: 'app-alert-edit-popup',
  providers: [
    // Currency format https://www.npmjs.com/package/ng2-currency-mask
    { provide: CURRENCY_MASK_CONFIG, useValue: {
      align: "left",
      allowNegative: false,
      decimal: ".",
      precision: 0,
      prefix: "",
      suffix: "",
      thousands: ","
      }
    },
  ],
  templateUrl: './alert-edit-popup.component.html',
  styleUrls: ['./alert-edit-popup.component.scss']
})
export class AlertEditPopupComponent implements OnInit {
  AlertPriorityValues = Object.values(AlertPriority);
  AlertTargetValues = Object.values(AlertTarget);
  DeviceDataPeriodsValues = Object.values(DeviceDataPeriods);
  form: FormGroup;
  alertDescriptionMessage = "";
  formKey = ALERT_FORM + this.data.alert?.id; // Used to save form state locally

  constructor(
    private formsManager: NgFormsManager,
    public dialogRef: MatDialogRef<AlertEditPopupComponent, AlertEditPopupResult>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AlertEditPopupOptions
  ) {
    this.setupPhoneAutocomplete();
    this.setupFcmAutocomplete();
  }

  ngOnInit() {
    this.initEmailFormArray(this.data.alert?.emails);
    this.initPhoneFormArray(this.data.alert?.phones);
    this.initFcmFormArray(this.data.alert?.fcms);

    this.form = this.formBuilder.group({
      priority: [this.data.alert?.priority, Validators.required],
      target: [this.data.alert?.target, Validators.required],
      period: [this.data.alert?.period, Validators.required],
      threshold: [this.data.alert?.threshold, [Validators.required, Validators.min(0)]],
      emails: this.emailFormArray,
      phones: this.phoneFormArray,
      fcms: this.fcmFormArray,
    });
    // Set validators on form level
    this.form.setValidators([
      this.atLeastOneRequiredValidator,
      this.uniqueAlertValidator,
    ]);
    // Keep alert message in sync
    this.form.valueChanges.subscribe(() => {
      this.alertDescriptionMessage = this.getAlertDescriptionMessage();
    });
    // Persist form
    let formsManagerOptions = {
      persistState: true,
      arrControlFactory: {
        emails: (value: string) => this.createEmailFormControl(value),
        phones: (value: string) => this.createPhoneFormControl(value),
        fcms: (value: string) => this.createFcmFormControl(value),
      },
    };
    this.formsManager.upsert(this.formKey, this.form, formsManagerOptions);
  }

  /**
   * Aleast one notification rule must be provided
   */
  atLeastOneRequiredValidator = (form: FormGroup) => {
    const emails = form.controls.emails;
    const phones = form.controls.phones;
    const fcms = form.controls.fcms;
    if(emails.value.length === 0 && phones.value.length === 0 && fcms.value.length === 0) {
      return { atLeastOneRequired : { text : 'At least one notification rule must be provided' } };
    }
    return null;
  }

  /**
   * Unique alert validator
   */
   uniqueAlertValidator = (form: FormGroup) => {
    const deviceExternalId = this.data.deviceExternalId;
    const priority = this.form.controls.priority.value;
    const target = this.form.controls.target.value;
    const period = this.form.controls.period.value;
    const threshold = this.form.controls.threshold.value;
    const alertCondition = `${deviceExternalId}-${priority}-${period}-${target}-${threshold}`;
    const otherExistingAlerts = this.data.alert ? this.data.allAlerts.filter(a => a.id !== this.data.alert.id) : this.data.allAlerts;
    if(otherExistingAlerts.some(a => a.alertCondition === alertCondition)) {
      return { alertNotUnique : { text : `An ${priority} alert already exists for device ${deviceExternalId} for ${period} ${target} with a threshold value of ${formatter.numberWithCommas(threshold)}` } };
    }
    return null;
  }

  ngOnDestroy() {
    this.formsManager.unsubscribe(this.formKey);
  }

  getAlertDescriptionMessage(): string {
    const deviceExternalId = this.data.deviceExternalId;
    const priority = this.form.controls.priority.value;
    const target = this.form.controls.target.value;
    const period = this.form.controls.period.value;
    const threshold = this.form.controls.threshold.value;
    const emails = this.form.controls.emails.value;
    const phones = this.form.controls.phones.value;
    const fcms = this.form.controls.fcms.value;
    return formatter.formatAlertDescriptionMessage(deviceExternalId, priority, target, period, threshold, emails, phones, fcms);
  }

  public async onClickCancel() {
    this.dialogRef.close();
  }

  public async onClickConfirm() {
    this.dialogRef.close(this.form.value);
  }

  public getErrorMessage(control: AbstractControl) {
    if(control.hasError('min')) {
      const min = control.getError('min').min
      return `You must enter a value greater than ${min}`;
    }

    if(control.hasError('max')) {
      const max = control.getError('max').max
      return `You must enter a value smaller than ${max}`;
    }

    if(control.hasError('required')) {
      return 'You must enter a value';
    }

    if(control.hasError('email')) {
      return 'You must enter valid emails';
    }

    if(control instanceof FormArray) {
      return control.controls.some(c => c.errors) ? 'You must enter valid values' : '';
    }

    return '';
  }

  checkFormArrayInvalid(formArray: FormArray) {
    return formArray.invalid || formArray.controls.some(c => c.invalid);
  }

  /** Email Chip List functions */

  @ViewChild('chipListEmail') chipListEmail: MatChipList;
  emailCtrl = new FormControl();
  emailFormArray: FormArray = new FormArray([]);

  initEmailFormArray(emails: string[]) {
    emails = emails || [];
    this.emailFormArray = this.formBuilder.array(emails.map(e => this.createEmailFormControl(e)));
  }

  addEmail(event: MatChipInputEvent): void {
    const email = (event.value || '').trim();
    if(email) {
      const existing: string[] = this.form.controls.emails.value;
      if(!existing.includes(email)) {
        this.emailFormArray.push(this.createEmailFormControl(email)); // Add email
        this.chipListEmail.errorState = this.checkFormArrayInvalid(this.emailFormArray); // Update error state
      }
    }
    event.input.value  = ''; // Clear the input value
    this.emailCtrl.setValue(null); // Clear the input form
  }

  removeEmail(email: FormControl): void {
    this.emailFormArray.removeAt(this.emailFormArray.controls.indexOf(email));
    this.chipListEmail.errorState = this.checkFormArrayInvalid(this.emailFormArray); // Update error state
  }

  createEmailFormControl(email: string): FormControl {
    return this.formBuilder.control(email, Validators.email)
  }

  /** Phone Chip List functions */

  @ViewChild('chipListPhone') chipListPhone: MatChipList;
  phoneCtrl = new FormControl();
  @ViewChild('phoneInput') phoneInput: ElementRef<HTMLInputElement>;
  filteredPhoneUsers: Observable<UserViewModel[]>;
  phoneFormArray: FormArray = new FormArray([]);

  initPhoneFormArray(phones: string[]) {
    phones = phones || [];
    this.phoneFormArray = this.formBuilder.array(phones.map(p => this.createPhoneFormControl(p)));
  }

  setupPhoneAutocomplete() {
    this.filteredPhoneUsers = this.phoneCtrl.valueChanges.pipe(
      startWith([null]),
      map((input: string | null) => {
        return this.data.allUsers
          .filter(u => u.phone) // Users with the phone field
          .filter(u => u.phone.startsWith('+91')) // Only users with a valid phone number
          .filter(u => !this.phoneFormArray.value.includes(u.phone.substr(3))) // Only users not already added
          .filter(u => u.phone.includes(input) || u.name.includes(input)) // Only matching phone numbers
      }),
    );
  }

  addPhone(event: MatChipInputEvent): void {
    let phone = (event.value || '').trim();
    phone = phone.startsWith('+91') ? phone.substr(3): phone;
    if(phone) {
      const existing: string[] = this.form.controls.phones.value;
      if(!existing.includes(phone)) {
        this.phoneFormArray.push(this.createPhoneFormControl(phone)); // Add phone
        this.chipListPhone.errorState = this.checkFormArrayInvalid(this.phoneFormArray); // Update error state
      }
    }
    event.input.value  = ''; // Clear the input value
    this.phoneCtrl.setValue(null); // Clear the input form
  }

  removePhone(phone: FormControl): void {
    this.phoneFormArray.removeAt(this.phoneFormArray.controls.indexOf(phone));
    this.chipListPhone.errorState = this.checkFormArrayInvalid(this.phoneFormArray); // Update error state
  }

  selectedPhone(event: MatAutocompleteSelectedEvent): void {
    const phone = event.option.value.substr(3);
    this.phoneFormArray.push(this.createPhoneFormControl(phone)); // Add phone
    this.chipListPhone.errorState = this.checkFormArrayInvalid(this.phoneFormArray); // Update error state
    this.phoneInput.nativeElement.value = ''; // Clear the input value
    this.phoneCtrl.setValue(null); // Clear the input form
  }

  formatPhone(phone: string): string {
    const fullPhone = '+91' + phone;
    const user = this.data.allUsers.find(u => u.phone === fullPhone);
    const name = user ? user.name : 'Unknown';
    return `${name} (${fullPhone})`;
  }

  createPhoneFormControl(phone: string): FormControl {
    return this.formBuilder.control(phone, [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('[0-9]*'),
    ])
  }

  /** Fcm Chip List functions */

  @ViewChild('chipListFcm') chipListFcm: MatChipList;
  fcmCtrl = new FormControl();
  @ViewChild('fcmInput') fcmInput: ElementRef<HTMLInputElement>;
  filteredFcmUsers: Observable<UserViewModel[]>;
  fcmFormArray: FormArray = new FormArray([]);

  initFcmFormArray(fcms: string[]) {
    fcms = fcms || [];
    this.fcmFormArray = this.formBuilder.array(fcms.map(p => this.createFcmFormControl(p)));
  }

  setupFcmAutocomplete() {
    this.filteredFcmUsers = this.fcmCtrl.valueChanges.pipe(
      startWith([null]),
      map((input: string | null) => {
        return this.data.allUsers
          .filter(u => !this.fcmFormArray.value.includes(u.id)) // Only users not already added
          .filter(u => u.name.includes(input)) // Only matching names
      }),
    );
  }

  addFcm(event: MatChipInputEvent): void {
    // DO NOTHING ONLY AUTOCOMPLETE SELECTION ALLOWED
  }

  removeFcm(fcm: FormControl): void {
    this.fcmFormArray.removeAt(this.fcmFormArray.controls.indexOf(fcm));
    this.chipListFcm.errorState = this.checkFormArrayInvalid(this.fcmFormArray); // Update error state
  }

  selectedFcm(event: MatAutocompleteSelectedEvent): void {
    const fcm = event.option.value;
    this.fcmFormArray.push(this.createFcmFormControl(fcm)); // Add fcm
    this.chipListFcm.errorState = this.checkFormArrayInvalid(this.fcmFormArray); // Update error state
    this.fcmInput.nativeElement.value = ''; // Clear the input value
    this.fcmCtrl.setValue(null); // Clear the input form
  }

  formatFcm(fcm: string): string {
    const user = this.data.allUsers.find(u => u.id === fcm);
    return user.name;
  }

  createFcmFormControl(fcm: string): FormControl {
    return this.formBuilder.control(fcm, [Validators.required])
  }
}
