import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment-timezone';
import { DatumViewModel } from 'src/app/models/datum_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { UnitsService } from 'src/app/services/units.service';
import { DeviceDataPeriods, ViewUiState } from 'src/app/utils/constants';
import * as constants from 'src/app/utils/constants';
import * as formatter from 'src/app/utils/formatter';
import { DeviceViewModel } from 'src/app/models/device_model';
import { GroupViewModel } from 'src/app/models/group_model';

@Component({
  selector: 'app-report-daily-print',
  templateUrl: './report-daily-print.component.html',
  styleUrls: ['./report-daily-print.component.scss']
})
export class ReportDailyPrintComponent implements OnInit {

  ViewUiState = ViewUiState; // For access in HTML

  uiState: ViewUiState;
  errorText: string;

  userId: string;
  user: UserViewModel;
  mappings: Array<MappingViewModel>;
  selectedMapping: MappingViewModel;
  selectedDate: moment.Moment;
  selectedTarget: DeviceViewModel | GroupViewModel;
  unitDetails;

  dayDatum: DatumViewModel;
  dayShifts: DatumViewModel[];
  dayBeforeDatum: DatumViewModel;
  dayBeforeShifts: DatumViewModel[];

  today = new Date();
  dailyShifts: any[];
  comparisonGauseColorScheme = { domain: [constants.getCssVariable('--primary-color')] };
  shiftChartColorScheme = { domain: ['#31C3DF', '#FFD47A', '#DC189C'] };
  shiftChartCumulativeData: Array<{ name: string, series: Array<{name: string, value: number}>}>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private butler: DataService,
  ) {
    this.unitDetails = this.butler.localService.getUnitDetails();
    this.userId = this.route.snapshot.queryParams.id;
    if(!this.userId) this.userId = this.butler.localService.getLocalUserId();
    if(this.route.snapshot.queryParams.date) {
      this.selectedDate = moment(this.route.snapshot.queryParams.date);
    }
    if(!this.selectedDate?.isValid() || this.selectedDate.isAfter(moment.tz('Asia/Kolkata'))) {
      this.selectedDate = moment.tz('Asia/Kolkata').startOf('day').subtract(1, 'day');
    }
  }

  ngOnInit(): void {
    this.fetchData()
  }

  private async fetchData() {
    try {
      this.uiState = ViewUiState.loading;
      const currentUserId = this.butler.localService.getLocalUserId();
      const currentUserOrg = this.butler.localService.getUserOrg();
      const user = await this.butler.userApi.getCachedUser(this.userId);
      this.user = new UserViewModel(user, currentUserId, currentUserOrg);
      // Fetch the devices and groups the user can see from the server
      const mappings = await this.butler.userApi.getUserMappings(this.userId);
      this.mappings = mappings.map(m => new MappingViewModel(m));
      // If a valid device id is present in query params, select that device
      if(this.route.snapshot.queryParams.device) {
        this.selectedMapping = this.mappings.find(m => m.deviceExternalId === this.route.snapshot.queryParams.device);
      }
      // Default to the first mapping if no device is selected
      if(!this.selectedMapping) {
        this.selectedMapping = this.mappings[0];
      }
      await this.fetchStatistics();
      await this.formatStatistics();
      this.uiState = ViewUiState.data;
      // setTimeout without a delay helps do something after dom finishes rendering
      setTimeout(() => window.print(), 1000);
      window.onafterprint = window.close;
    } catch(error) {
      this.errorText = "Failed to fetch user, please try again.";
      this.uiState = ViewUiState.error;
    }
  }

  async formatStatistics() {
    // Calculate data for reading comparison chart
    this.shiftChartCumulativeData = [];
    let shiftCumulative = 0;
    let series = this.dayBeforeShifts.map(s => {
      const timing = moment(s.end_time).format('hh:mm a');
      shiftCumulative = shiftCumulative + s.net_volume*this.unitDetails.volume;
      return { name: timing, value: shiftCumulative};
    });
    series = [ {name: '00:00 am', value: 0} , ...series]
    this.shiftChartCumulativeData.push({ name: this.selectedDate.clone().subtract(1, 'day').format('MMM DD'), series: series});
    shiftCumulative = 0;
    series = this.dayShifts.map(s => {
      const timing = moment(s.end_time).format('hh:mm a');
      shiftCumulative = shiftCumulative + s.net_volume*this.unitDetails.volume;
      return { name: timing, value: shiftCumulative};
    });
    series = [ {name: '00:00 am', value: 0} , ...series]
    this.shiftChartCumulativeData.push({ name: this.selectedDate.format('MMM DD'), series: series});
    // Calculate data for number cards
    let shiftNotStarted = false;
    let shiftCounter = 1;
    const dayStart = this.selectedDate.clone().startOf('day')
    const dayEnd = this.selectedDate.clone().startOf('day').add(1, 'day');
    this.dailyShifts = this.dayShifts
      .filter(s => moment(s.end_time) > dayStart && moment(s.end_time) <= dayEnd)
      .map(s => {
        let label = moment(s.end_time).format('hh:mm a');
        label = `Shift ${shiftCounter}: ${label}`
        shiftCounter++;
        if(shiftNotStarted) {
          label = `${label} (Not Started)`
        } else if(moment(s.end_time).isAfter(moment.tz('Asia/Kolkata'))) {
          label = `${label} (In Progress)`;
          shiftNotStarted = true;
        } else {
          label = `${label} (Completed)`;
        };
        return {
          name: label,
          value: s.net_volume,
        }
      });
  }

  private async fetchStatistics() {
    try {
      this.uiState = ViewUiState.loading;
      // Request the data for the first device from the server
      if(this.selectedMapping.deviceExternalId.startsWith('group')) {
        const group = await this.butler.groupApi.getGroup(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new GroupViewModel(group);
      } else {
        const device = await this.butler.deviceApi.getDevice(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new DeviceViewModel(device);
      }
      // Dates
      const dayStart = this.selectedDate.clone().startOf('day')
      const dayEnd = this.selectedDate.clone().startOf('day').add(1, 'day');
      const dayBeforeStart = dayStart.clone().subtract(1, 'day')
      const dayBeforeEnd = dayEnd.clone().subtract(1, 'day');
      // Fetch
      const dayResp = await this.butler.dataApi.getDailyData(this.userId, dayStart, dayEnd, this.selectedMapping.deviceExternalId);
      const dayShiftsResp = await this.butler.dataApi.getShiftData(this.userId, dayStart, dayEnd, this.selectedMapping.deviceExternalId);
      const dayBeforeResp = await this.butler.dataApi.getDailyData(this.userId, dayBeforeStart, dayBeforeEnd, this.selectedMapping.deviceExternalId);
      const dayBeforeShiftsResp = await this.butler.dataApi.getShiftData(this.userId, dayBeforeStart, dayBeforeEnd, this.selectedMapping.deviceExternalId);
      // Sanitize
      this.dayDatum = DatumViewModel.sanitizeDatum(DeviceDataPeriods.daily, dayStart, dayEnd, dayResp[0], this.selectedTarget);
      this.dayBeforeDatum = DatumViewModel.sanitizeDatum(DeviceDataPeriods.daily, dayBeforeStart, dayBeforeEnd, dayBeforeResp[0], this.selectedTarget);
      this.dayShifts = DatumViewModel.sanitizeData(DeviceDataPeriods.shift, dayStart, dayEnd, dayShiftsResp, this.selectedTarget);
      this.dayBeforeShifts = DatumViewModel.sanitizeData(DeviceDataPeriods.shift, dayBeforeStart, dayBeforeEnd, dayBeforeShiftsResp, this.selectedTarget);
      // Show UI
      this.uiState = ViewUiState.data;
    } catch(error) {
      this.errorText = "Failed to fetch device statistics, please try again.";
      this.uiState = ViewUiState.error;
    }
  }

  public literValueFormatting = (data: any): string => {
    let num = (typeof(data) === 'number') ? data : data.value;
    return formatter.numberWithCommas(Math.round(num*100)/100) + ' ' + this.unitDetails.symbols.volume;
  }

  public async onClickReset() {
    this.fetchData();
  }
}
