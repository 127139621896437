import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import generatePassword from 'password-generator';
import { combineLatest, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { InfoPopupComponent } from 'src/app/components/info-popup/info-popup.component';
import { CompanyModel, CompanyViewModel } from 'src/app/models/company_model';
import { UserModel, UserViewModel,UserCompanyModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { ROUTE_CGWA, ROUTE_USER, SELF_KEYWORD, UserOrgs, ViewUiState } from 'src/app/utils/constants';

@Component({
  selector: 'app-kritsnam-clients',
  templateUrl: './kritsnam-clients.component.html',
  styleUrls: ['./kritsnam-clients.component.scss']
})
export class KritsnamClientsComponent extends BaseComponent implements OnInit {
  userId: string;

  data: {users: UserViewModel[], companies: CompanyViewModel[]};
  data$: Subscription;

  inProgressCounter = 0;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    scrollingService: ScrollingService,
    router: Router,
    butler: DataService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.parent.paramMap.get('id');
    if(this.userId === SELF_KEYWORD) {
      this.userId = this.butler.localService.getLocalUserId();
    }
  }

  ngOnInit(): void {
    this.fetchData()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  private unsubscribeAll() {
    this.data$?.unsubscribe();
  }
  quickFilterText: string = '';

  // Method to handle search input changes
  onQuickFilterChanged(event: any) {
    this.quickFilterText = event.target.value;
  }
  private async fetchData() {
    this.unsubscribeAll();
    this.uiState = ViewUiState.loading;
    const usersObservable = this.butler.userApi.getAllUsersObservable();
    const companiesObservable = this.butler.companyApi.getAllCompaniesObservable();
    //alert(usersObservable)

   // this.data = {users: [], companies: []};
    this.uiState = ViewUiState.data;
    console.log('Fetched UserCompanyModel data:', usersObservable);
    usersObservable.subscribe(
      (users: UserViewModel[]) => {
        const currentUserId = this.butler.localService.getLocalUserId();
        const currentUserOrg = this.butler.localService.getUserOrg();
        const UserViewModels = users.map(u => new UserViewModel(u, currentUserId, currentUserOrg));

        this.data = { users: UserViewModels, companies: [] }; // Companies can stay as an empty array
        this.uiState = ViewUiState.data;
        console.log('Fetched UserViewModel data:', this.data);
      },
      (error) => {
        this.errorText = "Failed to fetch data, please try again.";
        this.uiState = ViewUiState.error;
        this.snackBar = { message: `${error.message}`, action: 'Close', config: { duration: 4000 } };
      }
    );
   /*  combineLatest([usersObservable, companiesObservable]).subscribe(
      (value: [UserCompanyModel[], CompanyModel[]]) => {
        const currentUserId = this.butler.localService.getLocalUserId();
        const currentUserOrg = this.butler.localService.getUserOrg();
        const UserCompanyModel = value[0].map(u => new UserCompanyModel(u, currentUserId, currentUserOrg));
        const companyViewModels = value[1].map(c => new CompanyViewModel(c));
        this.data = {users: UserCompanyModel, companies: companyViewModels};
        this.uiState = ViewUiState.data;
      },
      (error) => {
        this.errorText = "Failed to fetch data, please try again.";
        this.uiState = ViewUiState.error;
        this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
      }
    ); */
  }

  public async onClickReset() {
    this.fetchData();
  }

  public async onClickOpen(userId: string) {
    const user = this.data.users.find(u => u.id === userId);
    if(user.org === UserOrgs.kritsnam) {
      this.snackBar = {message: `Please select a user which does not belong to ${UserOrgs.kritsnam}`, action: 'Close', config: { duration: 4000 }};
      return;
    }
    if(user.cgwaOnly) {
     // this.router.navigate([ROUTE_CGWA], { queryParams: { id: user.id }, queryParamsHandling: 'merge' });
      //return;
      const cgwaUrl = this.router.serializeUrl(this.router.createUrlTree([ROUTE_CGWA], { queryParams: { id: user.id }, queryParamsHandling: 'merge' }));
      window.open(cgwaUrl, '_blank'); // Open in new tab
      return;
    }
  //  this.router.navigate([ROUTE_USER, userId]);
   // return;
   const userUrl = this.router.serializeUrl(this.router.createUrlTree([ROUTE_USER, userId]));
window.open(userUrl, '_blank'); // Open in new tab
return;
  }

  public async onClickSetPassword(user: UserViewModel) {
    try {
      const resetting: boolean = user.isPasswordLoginEnabled();
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        width: '400px',
        data: {
          message: `${resetting ? 'Reset': 'Set' } password for user ${user.name}? This will allow login via userid and password.`,
          title: `${resetting ? 'Reset': 'Set' } password`,
          confirmColor: "primary",
        }
      });
      const result = await dialogRef.afterClosed().toPromise()
      if(result) {
        this.inProgressCounter++;
        // Generate a random password
        //const maxLength = 15;
        //const minLength = 8;
        //var randomLength = Math.floor(Math.random() * (maxLength - minLength)) + minLength;
        //const value = generatePassword(randomLength, false);

        // Set the password length to 8 characters
        const passwordLength = 8;
        const value = generatePassword(passwordLength, false);
        await this.butler.userApi.setUserCredential(user.id, value);
        const dialogRef = this.dialog.open(InfoPopupComponent, {
          width: '400px',
          data: {
            message: `The password has been ${resetting ? 'reset': 'set' } for user ${user.name} to '${value}'.`,
            title: `Confidential Information`,
          }
        });
        const result = await dialogRef.afterClosed().toPromise()
        this.inProgressCounter--;
      }
    } catch (error) {
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }
}
