import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AlertModel } from 'src/app/models/alert_model';
import { BackendService } from 'src/app/services/backend.service';
import { AlertPriority, AlertTarget, DeviceDataPeriods } from 'src/app/utils/constants';
import { LocalService } from '../local.service';
import { SocketService } from '../socket.service';
import { DeviceModel } from 'src/app/models/device_model';

@Injectable({
  providedIn: 'root'
})
export class DeviceApi {

  constructor(
    private localService: LocalService,
    private backendService: BackendService,
    private socketService: SocketService,
  ) {
  }

  public async getDevice(deviceExternalId: string): Promise<DeviceModel> {
    const data = await this.backendService.get(`/devices/${deviceExternalId}`).toPromise();
    const device: DeviceModel = DeviceModel.parse(data);
    return device;
  }

  public getAlertsObservable(userId: string, deviceExternalId: string): Observable<AlertModel[]> {
    const endpoint = `/devices/${deviceExternalId}/alerts`;
    const observedEndpoint = this.socketService.observableOf(endpoint);
    return observedEndpoint.pipe(flatMap(async (trigger: boolean) => {
      const data = await this.backendService.post(endpoint, {userId: userId}).toPromise();
      const results: AlertModel[] = data.map(alert => AlertModel.parse(alert));
      return results;
    }))
  }

  public async createAlert(
    deviceExternalId: string, priority: AlertPriority, target: AlertTarget,
    period: DeviceDataPeriods, threshold: number, emails: string[], phones: string[], fcms: string[],
  ) {
    const data = {
      priority: priority,
      target: target,
      period: period,
      threshold: threshold,
      emails: emails,
      phones: phones,
      fcms: fcms,
    }
    await this.backendService.post(`/devices/${deviceExternalId}/alerts/new`, data).toPromise();
    this.socketService.pingHook(`/devices/${deviceExternalId}/alerts`);
  }

  public async editAlert(
    deviceExternalId: string, alertId: string, priority: AlertPriority, target: AlertTarget,
    period: DeviceDataPeriods, threshold: number, emails: string[], phones: string[], fcms: string[],
  ) {
    const data = {
      priority: priority,
      target: target,
      period: period,
      threshold: threshold,
      emails: emails,
      phones: phones,
      fcms: fcms,
    }
    await this.backendService.post(`/devices/${deviceExternalId}/alerts/${alertId}`, data).toPromise();
    this.socketService.pingHook(`/devices/${deviceExternalId}/alerts`);
  }

  public async deleteAlert(deviceExternalId: string, alertId: string) {
    await this.backendService.delete(`/devices/${deviceExternalId}/alerts/${alertId}`).toPromise();
    this.socketService.pingHook(`/devices/${deviceExternalId}/alerts`);
  }

  public async testAlert(deviceExternalId: string, alertId: string) {
    await this.backendService.post(`/devices/${deviceExternalId}/alerts/${alertId}/test`, {}).toPromise();
  }

  async getCalibrationCertificate(deviceExternalId: string): Promise<string> {
    const resp = await this.backendService.get(`/devices/${deviceExternalId}/calibrationCertificate`).toPromise();
    return resp.url;
  }
}
