import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import moment from 'moment-timezone';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';
import * as formatter from "src/app/utils/formatter";
import * as exporter from 'src/app/utils/exporter';
import { DeviceViewModel } from 'src/app/models/device_model';
import { GroupViewModel } from 'src/app/models/group_model';

@Component({
  selector: 'report-shift-view',
  templateUrl: './report-shift-view.component.html',
  styleUrls: ['./report-shift-view.component.scss']
})
export class ReportShiftViewComponent extends BaseViewComponent implements OnInit {

  @Input('userId')
  userId: string;

  @Input('user')
  user: UserViewModel;

  @Input('mappings')
  mappings: Array<MappingViewModel>;

  @Input('selectedMapping')
  selectedMapping: MappingViewModel;

  @Input('selectedDate')
  selectedDate: moment.Moment;

  @Input('selectedTarget')
  selectedTarget: DeviceViewModel | GroupViewModel;

  @Input('shift')
  shift: DatumViewModel[];

  @Input('bannerText')
  bannerText: string;

  @Input('inProgressCounter')
  inProgressCounter: number;

  @Input('unitDetails')
  unitDetails;

  @Output('mappingChangeClick')
  mappingChangeClick = new EventEmitter<MappingViewModel>();

  @Output('dateChangeClick')
  dateChangeClick = new EventEmitter<any>();

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  today = new Date();
  shiftChartData: Array<{name: string, series: Array<{name: string, value: number}>}> = [];
  shiftChartColorScheme = { domain: ['#31C3DF', '#FFD47A', '#DC189C'] };
  shiftAggregates: Array<{timing: string, value: DatumViewModel}>;

  columnDefs = [
    { field: 'Time', valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY hh:mm A'), cellStyle: {'text-align': 'right'}},
    { field: 'Work Hours', valueFormatter: (params) => formatter.getWorkHourString(params.value), cellStyle: {'text-align': 'right'}},
    {
      field: 'Net Volume',
      valueFormatter: (params) => formatter.numberWithCommas(Math.round(params.value*100)/100) + " " + this.unitDetails.symbols.volume,
      cellStyle: {'text-align': 'right'}
    },
    {
      field: 'Flow Rate',
      valueFormatter: (params) => formatter.numberWithCommas(Math.round(params.value*100)/100) + " " + this.unitDetails.symbols.flowrate,
      cellStyle: {'text-align': 'right'}
    },
  ];

  shiftTableData = [];

  agGridContext = {
    timezone: null
  };

  @ViewChild('agGrid') agGrid: AgGridAngular;
  private api: GridApi;
  private columnApi: ColumnApi;

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.uiState !== this.ViewUiState.data) return;
    // Calculate the aggregates for each shift
    this.shiftAggregates = [];
    for(let s of this.shift) {
      const timing = moment(s.end_time).format('hh:mm a');
      const timingAggregate = this.shiftAggregates.find(a => a.timing === timing);
      timingAggregate ? timingAggregate.value.add(s): this.shiftAggregates.push({timing: timing, value: s.clone()});
    }
    // Show the chart for the shift-wise report
    this.shiftChartData = [];
    for(let s of this.shift) {
      const t = moment(s.end_time);
      const d = moment(s.end_time).startOf('day');
      // Skip all entries out of the current week
      if(t.isBefore(this.selectedDate.clone().startOf('week')) ||
         t.isAfter(this.selectedDate.clone().startOf('week').add(1, 'week'))
        ) continue;
      // Midnight reading is counted in previous day
      if(t.isSame(d)) d.subtract(1, 'day');
      const label = `${d.format('DD/MM')} to ${d.clone().add(1, 'day').format('DD/MM')}`
      const dataOfDay = this.shiftChartData.find(d => d.name === label);
      if(!dataOfDay) {
        this.shiftChartData.push({
          name: label,
          series: [{
            name: moment(s.end_time).format('hh:mm a'),
            value: s.net_volume * this.unitDetails.volume
          }]
        });
      } else {
        dataOfDay.series.push({
          name: moment(s.end_time).format('hh:mm a'),
          value: s.net_volume * this.unitDetails.volume
        });
      }
    }
    // Show the table for shift-wise report
    this.shiftTableData = this.shift.map(s => ({
      'Time': s.start_time,
      'Work Hours': s.workhour,
      'Net Volume': s.net_volume * this.unitDetails.volume,
      'Flow Rate': s.flowrate * this.unitDetails.volume/this.unitDetails.time,
    }));
  }

  gridReady(params: GridReadyEvent) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.autoSizeAll(false);
    const grid = document.getElementsByClassName('ag-center-cols-container')[0]
    const gridWidth = (grid as HTMLElement).offsetWidth;
    const screenWidth = document.body.offsetWidth;
    if (screenWidth > gridWidth) {
      this.api.sizeColumnsToFit();
    }
  }

  autoSizeAll(skipHeader: boolean) {
    var allColumnIds = [];
    this.columnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  async onClickMappingChange(mapping: MappingViewModel) {
    this.mappingChangeClick.emit(mapping);
  }

  async onClickDateChange(date: any) {
    this.dateChangeClick.emit(date);
  }

  async onClickReset() {
    this.resetClick.emit(true);
  }

  onClickPrint() {
    // Open in new tab because window.print breaks flexbox styling
    // Flexbox breakpoints don't run! so fuck it, opening it in new tab
    window.open(`${window.location.pathname}/print${window.location.search}`, '_blank');
  }

  async onClickDownload() {
    const fileName = `Shiftwise Data Report ${this.selectedDate.format('MMM YYYY')}`;
    const sheetName = 'Shift Data';
    exporter.exportData(fileName, sheetName, this.shift);
  }
}
