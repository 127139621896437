<div id="login-page" class="background">
    <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <img style="width: 100%; max-width:400px;">
        <mat-card>
            <div [hidden]="loginViewUiState !== LoginViewUiState.unselected">
                <mat-card-header>
                    <mat-card-title>Login</mat-card-title>
                    <mat-card-subtitle>Monitoring every drop of water in realtime</mat-card-subtitle>
                </mat-card-header>
                <button id="go-to-password-login" mat-stroked-button color="primary" type="button" (click)="onClickPasswordLogin()">
            Login with password
        </button>
                <p style="margin-top:8px; margin-bottom:0; text-align: center;">OR</p>
                <button id="go-to-otp-login" mat-stroked-button color="primary" type="button" (click)="onClickReset()" style="margin-top:8px;">
          Login with OTP
        </button>
            </div>

            <div [hidden]="loginViewUiState !== LoginViewUiState.phone">
                <mat-card-header>
                    <mat-card-title>Login</mat-card-title>
                    <mat-card-subtitle>Monitoring every drop of water in realtime</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="phoneForm">
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Phone Number</mat-label>
                            <span matPrefix>+91&nbsp;</span>
                            <input matInput id="phone-input" type="tel" [errorStateMatcher]="ocdErrorMatcher" formControlName="phone">
                            <mat-hint>OTP will be sent via SMS</mat-hint>
                            <mat-error id="phone-error" *ngIf="phoneForm.controls.phone.invalid">{{getErrorMessage(phoneForm.controls.phone)}}</mat-error>
                        </mat-form-field>
                        <div id="recaptcha-container">
                        </div>
                        <button id="phone-submit" mat-stroked-button color="primary" type="submit" [disabled]="!phoneForm.valid || !recaptchaVerified" (click)="onClickSubmitPhone()">Send OTP</button>
                        <p style="margin-top:8px; margin-bottom:0; text-align: center;">OR</p>
                        <div style="width: 100%; text-align: center; padding-top: 8px;">
                            <a style="cursor: pointer;" (click)="onClickPasswordLogin()">Login with password</a>
                        </div>
                    </form>
                    <app-spinner *ngIf="phoneForm.pending"></app-spinner>
                </mat-card-content>
            </div>

            <div [hidden]="loginViewUiState !== LoginViewUiState.otp">
                <mat-card-header>
                    <mat-card-title>Login</mat-card-title>
                    <mat-card-subtitle>Monitoring every drop of water in realtime</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="otpForm" (ngSubmit)="onClickSubmitOTP()" autocomplete="off">
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>One Time Password (OTP)</mat-label>
                            <input matInput id="otp-input" type="tel" [errorStateMatcher]="ocdErrorMatcher" formControlName="otp">
                            <mat-hint>OTP SMS may take a few seconds</mat-hint>
                            <mat-error id="otp-error" *ngIf="otpForm.controls.otp.invalid">{{getErrorMessage(otpForm.controls.otp)}}</mat-error>
                        </mat-form-field>
                        <button id="otp-submit" mat-stroked-button color="primary" type="submit" [disabled]="!otpForm.valid">Submit OTP</button>
                        <div style="width: 100%; text-align: center; padding-top: 8px;">
                            <a style="cursor: pointer;" (click)="onClickReset()">Change number/resend OTP</a>
                        </div>
                    </form>
                    <app-spinner *ngIf="otpForm.pending"></app-spinner>
                </mat-card-content>
            </div>

            <div [hidden]="loginViewUiState !== LoginViewUiState.loading">
                <mat-spinner id="submit-spinner" style="margin: auto;"></mat-spinner>
            </div>

            <div [hidden]="loginViewUiState !== LoginViewUiState.error">
                <mat-card-header>
                    <mat-card-title>Error</mat-card-title>
                    <mat-card-subtitle id="error-text">{{ errorText }}</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <button mat-stroked-button color="primary" (click)="onClickReset()">Reset</button>
                </mat-card-content>
            </div>

            <div [hidden]="loginViewUiState !== LoginViewUiState.password">
                <mat-card-header>
                    <mat-card-title>Login</mat-card-title>
                    <mat-card-subtitle>Monitoring every drop of water in realtime</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <form [formGroup]="passwordForm">
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>ID</mat-label>
                            <input matInput id="id-input" type="text" [errorStateMatcher]="ocdErrorMatcher" formControlName="id">
                            <mat-hint>10 digit phone number or userid</mat-hint>
                            <mat-error id="id-error" *ngIf="passwordForm.controls.id.invalid">{{getErrorMessage(passwordForm.controls.id)}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Password</mat-label>
                            <input matInput id="password-input" [type]="hidePassword ? 'password' : 'text'" [errorStateMatcher]="ocdErrorMatcher" formControlName="password">
                            <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-hint>Password are provided by Kritsnam</mat-hint>
                            <mat-error id="password-error" *ngIf="passwordForm.controls.password.invalid">{{getErrorMessage(passwordForm.controls.password)}}</mat-error>
                        </mat-form-field>
                        <div id="password-recaptcha-container">
                            <re-captcha #ngRecaptcha formControlName="captcha"></re-captcha>
                        </div>
                        <button id="password-submit" mat-stroked-button color="primary" type="submit" [disabled]="!passwordForm.valid" (click)="onClickSubmitPassword()">Submit</button>
                        <p style="margin-top:8px; margin-bottom:0; text-align: center;">OR</p>
                        <div style="width: 100%; text-align: center; padding-top: 8px;">
                            <a style="cursor: pointer;" (click)="onClickReset()">Login with OTP</a>
                        </div>
                    </form>
                    <app-spinner *ngIf="phoneForm.pending"></app-spinner>
                </mat-card-content>
            </div>
            <div style="margin-top:30px">
                <mat-card-header>

                    <mat-card-subtitle><a id="go-to-otp-login" href="https://demo-signup.dhaara.com/" color="primary" style="margin-top:8px;">
                  Sign Up for Dhaara Live
                </a></mat-card-subtitle>
                </mat-card-header>
            </div>

        </mat-card>
    </div>
</div>
