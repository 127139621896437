import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss']
})
export class ErrorViewComponent implements OnInit {

  @Input('errorText')
  errorText: string;

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickRetry() {
    this.resetClick.emit(true);
  }
}
