import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ROUTE_HOME, ROUTE_LOGIN, UserOrgs } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {

  constructor(
    private butler: DataService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkAuthenticated(state, route)
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthenticated(state, route)
  }

  checkAuthenticated(state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
    // redirect if not logged in
    const authenticated: boolean = this.butler.backendService.authenticationState.getValue();
    if (!authenticated) {
      // not logged in so redirect to login page with the return url and return false
      this.router.navigate([ROUTE_LOGIN], { queryParams: { returnUrl: state.url }});
      return false;
    }
    let orgs = route.data.auth?.orgs as UserOrgs[];
    const userOrg = this.butler.localService.getUserOrg();
    if(orgs && !orgs.includes(userOrg)) {
      // not authorized so redirect to home page
      this.router.navigate([ROUTE_HOME]);
      return false;
    }
    if('auth' in route.data && 'cgwa' in route.data.auth) {
      const allowCgwaUser = route.data.auth.cgwa;
      if(!allowCgwaUser && this.butler.localService.isUserCGWA()) {
        // not authorized so redirect to home page
        this.router.navigate([ROUTE_HOME]);
        return false;
      }
    }
    return true;
  }
}
