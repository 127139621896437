import { Injectable } from '@angular/core';
import { UserModel } from 'src/app/models/user_model';
import { DARK_THEME_KEY, NOTIFICATION_DND_KEY, PHONE_KEY, TOKEN_KEY, UserOrgs, USER_KEY } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() {}

  public getUserOrg(): UserOrgs {
    const user: UserModel = this.getLocalUser();
    return user?.org;
  }

  public isUserCGWA(): boolean {
    const user: UserModel = this.getLocalUser();
    return user?.cgwaOnly;
  }

  public isUserKritsnam(): boolean {
    const user: UserModel = this.getLocalUser();
    return user?.org === UserOrgs.kritsnam;
  }

  public getLocalToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getLocalUser(): UserModel | null {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

  public getLocalUserId() {
    const user: UserModel = this.getLocalUser();
    return user?.id;
  }

  public setLocalToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  public removeLocalToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  public setLocalUser(user: UserModel) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public removeLocalUser() {
    localStorage.removeItem(USER_KEY);
  }

  public setLocalPhoneNumber(phone: string) {
    localStorage.setItem(PHONE_KEY, phone);
  }

  public getLocalPhoneNumber() {
    return localStorage.getItem(PHONE_KEY);
  }

  public setDarkThemeState(dark: boolean) {
    localStorage.setItem(DARK_THEME_KEY, dark.toString());
  }

  public getDarkThemeState() {
    return localStorage.getItem(DARK_THEME_KEY) === "true";
  }

  public getUnits(){
    let units = localStorage.getItem('units')
    units = units ? units : 'CubicMeters'
    return units;
  }

  public setUnits(units: string){
    localStorage.setItem('units',units);
  }

  public getUnitDetails(): {
    volume: number,
    time: number,
    symbols: {volume: string, time: string, flowrate: string}
  }{
    const units = this.getUnits()
    switch(units){
      case 'Liters': return {
        volume: 1000,
        time: 3600,
        symbols: {volume: "ltr", time: "s", flowrate: "ltr/sec"}
      }
      case 'Litershr': return {
        volume: 1000,
        time: 1,
        symbols: {volume: "ltr", time: "hr", flowrate: "ltr/hr"}
      }
      case 'Gallons': return {
        volume: 264.172,
        time: 60,
        symbols: {volume: "Gal", time: "min", flowrate: "Gal/min"}
      }
      case 'CubicMeters': return {
        volume: 1,
        time: 1,
        symbols: {volume: "m³", time: "hr", flowrate: "m³/hr"}
      }
      default: return {
        volume: 1,
        time: 1,
        symbols: {volume: "", time: "", flowrate: ""}
      }
    }
  }

  public setNotificationDnd(value: boolean) {
    localStorage.setItem(NOTIFICATION_DND_KEY, value.toString());
  }

  public getNotificationDnd() {
    return localStorage.getItem(NOTIFICATION_DND_KEY) === "true";
  }

  public removeLocalStorage() {
    const phone = this.getLocalPhoneNumber();
    localStorage.clear();
    if(phone) this.setLocalPhoneNumber(phone);
  }
}
