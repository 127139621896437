<div id="report-daily-page" class="page" fxLayout="column">
  <app-toolbar-view
  class="app-toolbar"
  [title]="title"
  [showBackButton]="true"
  [class.poof]="scrollingDown"
  [userImg]="getUserImage()"
  [userName]="getUserName()"
  (backClick)="onClickBack()"
  (themeClick)="onClickTheme()"
  (logoutClick)="onClickLogout()"
  (unitsClick)="onClickUnits($event)">
  </app-toolbar-view>

  <div fxFlex="1 1 auto"
  style="padding-top: 64px; overflow-y: auto;"
  scrollDirection (scrolledUp)="setScrolling(false)" (scrolledDown)="setScrolling(true)">
    <report-daily-view
    style="width: 100%; height: 100%"
    [user]="user"
    [mappings]="mappings"
    [selectedMapping]="selectedMapping"
    [selectedDate]="selectedDate"
    [selectedTarget]="selectedTarget"
    [dayDatum]="dayDatum"
    [dayShifts]="dayShifts"
    [dayBeforeDatum]="dayBeforeDatum"
    [dayBeforeShifts]="dayBeforeShifts"
    [bannerText]="bannerText"
    [inProgressCounter]="inProgressCounter"
    [uiState]="uiState"
    [errorText]="errorText"
    [snackbar]="snackBar"
    [unitDetails]="unitDetails"
    (mappingChangeClick)="onClickMappingChange($event)"
    (dateChangeClick)="onClickDateChange($event)"
    (resetClick)="onClickReset()"
    ></report-daily-view>
  </div>
</div>
