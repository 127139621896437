import moment from 'moment-timezone';
import * as formatter from 'src/app/utils/formatter';

/**
 * Server model
 */
export class DeviceModel {

  constructor(
    public id: string,
    public externalId: string,
    public name: string,
    public type: string,
    public companyExternalId: string,
    public hardwareShifts: string,
    public softwareShifts: string,
    public dailyConsumptionCycle: string,
    public lastCalibrationDate: Date,
    public certificateFileName: string,
    public batteryPercentage: number,
    public signalPercentage: number,
    public lastCommunicationDate: Date,
    public dailyNocLimit: number,
    public borewellID: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(item: any): DeviceModel {
    return new DeviceModel(
      item._id || null,
      item.externalId || null,
      item.name || null,
      item.type || null,
      item.companyExternalId || null,
      item.hardwareShifts || null,
      item.softwareShifts || null,
      item.dailyConsumptionCycle || null,
      new Date(item.lastCalibrationDate) || null,
      item.certificateFileName || null,
      item.batteryPercentage ? +item.batteryPercentage : 100,
      item.signalPercentage ? +item.signalPercentage : 100,
      item.lastCommunicationDate?new Date(item.lastCommunicationDate) : null,
      +item.dailyNocLimit || null,
      item.borewellID || null,
      new Date(item.createdAt) || null,
      new Date(item.updatedAt) || null,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class DeviceViewModel extends DeviceModel {

  isGroup = false; // to quickly check if the target is a group in html

  constructor(device: DeviceModel) {
    super(
      device.id,
      device.externalId,
      device.name,
      device.type,
      device.companyExternalId,
      device.hardwareShifts,
      device.softwareShifts,
      device.dailyConsumptionCycle,
      device.lastCalibrationDate,
      device.certificateFileName,
      device.batteryPercentage,
      device.signalPercentage,
      device.lastCommunicationDate,
      device.dailyNocLimit,
      device.borewellID,
      device.createdAt,
      device.updatedAt,
    )
  }

  /** Shortcuts to access formatter in HTML Template */
  getShortId = () => formatter.getShortId(this);

  getShiftTimeOffsets(): Array<{start: number, end: number}> {
    let shiftConfig: string = this.hardwareShifts;
    if(!shiftConfig){
      shiftConfig = "00:00, 08:00, 16:00";
    }
    const parsedShifts: Array<string> = shiftConfig.split(",");
    return parsedShifts.map((s: string, index: number, array: string[]) => {
      const startTime = s.split(":");
      let endTime: (string | number)[];
      if(index === 2){
        endTime = array[0].split(":");
      }else{
        endTime = array[index + 1].split(":");
      }
      const start = +startTime[0] * 60 + +startTime[1];
      let end = +endTime[0] * 60 + +endTime[1];
      if(index === 2){
        const shiftStart = array[0].split(":");
        const startt = moment.tz("Asia/Kolkata").startOf('day');
        const endd = startt.clone().add(shiftStart[0], 'h').add(shiftStart[1], 'm');
        var duration = moment.duration(endd.diff(startt));
        var hours = duration.asMinutes();
        end = 1440 + hours
      }
      return { start, end };
    })
  }

  /**
   * Generate the time objects for all the shifts between two days
   */
  getShiftsBetween(
    startTime: moment.Moment,
    endTime: moment.Moment
    ): Array<{start_time: moment.Moment, end_time: moment.Moment}> {
    const day = startTime.clone().startOf('day');
    const shifts: Array<{start_time: moment.Moment, end_time: moment.Moment}> = [];
    const shiftOffsets = this.getShiftTimeOffsets();
    while(day.isBefore(endTime)) {
      const dayShifts =shiftOffsets.map((value: {start: number, end: number}) =>  {
        return {
          start_time: day.clone().add(value.start, 'minutes'),
          end_time: day.clone().add(value.end, 'minutes'),
        }
      });
      shifts.push(...dayShifts);
      day.add(1, 'day');
    }
    return shifts;
  }
}
