import { AlertPriority, AlertTarget, DeviceDataPeriods } from 'src/app/utils/constants';
import * as formatter from 'src/app/utils/formatter';

/**
 * Server model
 */
export class AlertModel {

   constructor(
    public id: string,
    public deviceExternalId: string,
    public priority: AlertPriority,
    public target: AlertTarget,
    public period: DeviceDataPeriods,
    public threshold: number,
    public alertCondition: string,
    public emails: string[],
    public phones: string[],
    public fcms: string[],
    public createdBy: string,
    public modifiedBy: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(item: any): AlertModel {
    return new AlertModel(
      item._id || null,
      item.deviceExternalId || null,
      item.priority || null,
      item.target || null,
      item.period || null,
      +item.threshold || null,
      item.alertCondition || null,
      item.emails || null,
      item.phones || null,
      item.fcms || null,
      item.createdBy || null,
      item.modifiedBy || null,
      new Date(item.createdAt) || null,
      new Date(item.updatedAt) || null,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class AlertViewModel extends AlertModel {

  constructor(alert: AlertModel) {
    super(
      alert.id,
      alert.deviceExternalId,
      alert.priority,
      alert.target,
      alert.period,
      alert.threshold,
      alert.alertCondition,
      alert.emails,
      alert.phones,
      alert.fcms,
      alert.createdBy,
      alert.modifiedBy,
      alert.createdAt,
      alert.updatedAt,
    )
  }

  /** Shortcuts to access formatter in HTML Template */
  getShortId = () => formatter.getShortId(this);
  formatAlertDescriptionMessage = () => formatter.formatAlertDescriptionMessage(
    this.deviceExternalId,
    this.priority,
    this.target,
    this.period,
    this.threshold,
    this.emails,
    this.phones,
    this.fcms,
  )
  numberWithCommas = (number: number) => formatter.numberWithCommas(number);
}
