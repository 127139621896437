import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { GroupModel } from 'src/app/models/group_model';

@Injectable({
  providedIn: 'root'
})
export class GroupApi {

  constructor(
    private backendService: BackendService,
  ) {
  }

  public async getGroup(groupExternalId: string): Promise<GroupModel> {
    const data = await this.backendService.get(`/groups/${groupExternalId}`).toPromise();
    const group: GroupModel = GroupModel.parse(data);
    return group;
  }
}
