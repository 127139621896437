import moment from "moment";
import * as formatter from "src/app/utils/formatter";
import { DeviceDataPeriods } from "../utils/constants";
import { DeviceViewModel } from "./device_model";
import { GroupViewModel } from "./group_model";

/**
 * Server model
 */
export class DatumModel {

   constructor(
    public start_time: Date,
    public end_time: Date,
    public serial_number: string,
    public forward_flow: number,
    public reverse_flow: number,
    public net_volume: number,
    public flowrate: number,
    public workhour: number,
    public electricity_bill: number,
    public water_bill: number,
    ) {}

  public static parse(item: any): DatumModel {
    return new DatumModel(
      new Date(item.start_time),
      new Date(item.end_time),
      item.serial_number,
      Math.round(+item.forward_flow / 1000 * 100) / 100, // Two decimal places lt to m3
      Math.round(+item.reverse_flow / 1000 * 100) / 100, // Two decimal places lt to m3
      Math.round(+item.net_volume / 1000 * 100) / 100, // Two decimal places lt to m3
      Math.round(+item.flowrate * 3.6 * 100) / 100, // Two decimal places lt/s to m3/hr
      +item.workhour,
      +item.electricity_bill,
      +item.water_bill,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class DatumViewModel extends DatumModel {

  constructor(datum: DatumModel) {
    super(
      datum.start_time,
      datum.end_time,
      datum.serial_number,
      datum.forward_flow,
      datum.reverse_flow,
      datum.net_volume,
      datum.flowrate,
      datum.workhour,
      datum.electricity_bill,
      datum.water_bill,
    );
  }

  getWorkHourString = () => formatter.getWorkHourString(this.workhour);

  static zero(start_time: moment.Moment, end_time: moment.Moment) {
    return new DatumViewModel(new DatumModel(start_time.toDate(), end_time.toDate(), '', 0, 0, 0, 0, 0, 0, 0,));
  }

  clone(): DatumViewModel {
    return new DatumViewModel(this);
  }

  add(another: DatumViewModel) {
    this.start_time = null;
    this.end_time = null;
    this.serial_number = null;
    this.forward_flow = this.forward_flow + another.forward_flow;
    this.reverse_flow = this.reverse_flow + another.reverse_flow;
    this.net_volume = this.net_volume + another.net_volume;
    this.flowrate = (this.flowrate + another.flowrate) / 2;
    this.workhour = this.workhour + another.workhour;
    this.electricity_bill = this.electricity_bill + another.electricity_bill;
    this.water_bill = this.water_bill + another.water_bill;
  }

  isAtTime(time: {start_time: moment.Moment | null, end_time: moment.Moment | null}): boolean {
    const sameStart =
      time.start_time ?
        moment(this.start_time).isBetween(time.start_time.clone().subtract(5,'minutes'),time.start_time.clone().add(5,'minutes'))
        : true;
    const sameEnd =
      time.end_time ?
        moment(this.end_time).isBetween(time.end_time.clone().subtract(5,'minutes'),time.end_time.clone().add(5,'minutes'))
        : true;
    return sameStart && sameEnd;
  }


  isSameDate(time: {start_time: moment.Moment | null, end_time: moment.Moment | null}): boolean {
    const sameStart =
      time.start_time ?
        moment(this.start_time).isSame(time.start_time, 'day')
        : true;
    const sameEnd =
      time.end_time ?
        moment(this.end_time).isSame(time.end_time, 'day')
        : true;
    return sameStart && sameEnd;
  }



  /**
   * Helper function to sanitize a single data point
   */
  static sanitizeDatum(
    period: DeviceDataPeriods,
    startTime: moment.Moment,
    endTime: moment.Moment,
    data: DatumModel,
    device: DeviceViewModel | GroupViewModel): DatumViewModel {
      return this.sanitizeData(period, startTime, endTime, data ? [data]: [], device)[0];
  }

  static sanitizeData(
    period: DeviceDataPeriods,
    startTime: moment.Moment,
    endTime: moment.Moment,
    data: Array<DatumModel>,
    target: DeviceViewModel | GroupViewModel): Array<DatumViewModel> {
    const result: Array<DatumViewModel> = [];
    const dataViewModels = data.map(d => new DatumViewModel(d));
    switch(period) {
      case DeviceDataPeriods.shift:
        console.log("dataViewModels", dataViewModels);
        const shifts = target.getShiftsBetween(startTime, endTime);
        for(let shift of shifts) {
          const d = dataViewModels.find(d => d.isAtTime(shift));
          console.log(d);
          if(d) {
            result.push(d);
          } else {
            result.push(DatumViewModel.zero(shift.start_time, shift.end_time));
          }
        }
        break;
      case DeviceDataPeriods.daily:
        const dayStart = startTime.clone().startOf('day');
        const dayEnd = startTime.clone().startOf('day').add(1, 'days');
        while(dayStart.isBefore(endTime)) {
          const d = dataViewModels.find(d => d.isSameDate({start_time: dayStart, end_time: dayEnd}));

          if(d) {
            result.push(d);
          } else {
            result.push(DatumViewModel.zero(dayStart, dayEnd));
          }
          dayStart.add(1, 'day');
          dayEnd.add(1, 'day');
        }
        break;
      case DeviceDataPeriods.weekly:
        const weekStart = startTime.clone().startOf('week').add(1, 'days');
        const weekEnd = startTime.clone().startOf('week').add(1, 'weeks');
        while(weekStart.isBefore(endTime)) {
          const d = dataViewModels.find(d => d.isSameDate({start_time: weekStart, end_time: weekEnd}));

          if(d) {
            result.push(d);
          } else {
            result.push(DatumViewModel.zero(weekStart, weekEnd));
          }
          weekStart.add(1, 'weeks');
          weekEnd.add(1, 'weeks');
        }
        break;
      case DeviceDataPeriods.monthly:
        const monthStart = startTime.clone().startOf('month');
        const monthEnd = startTime.clone().endOf('month');
        while(monthStart.isBefore(endTime)) {
          const d = dataViewModels.find(d => d.isSameDate({start_time: monthStart, end_time: monthEnd}));

          if(d) {
            result.push(d);
          } else {
            result.push(DatumViewModel.zero(monthStart, monthEnd));
          }
          monthStart.add(1, 'months');
          monthEnd.add(1, 'months');
        }
        break;
    }
    return result;
  }
}
