import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { ScrollingService } from 'src/app/services/scrolling.service';

@Component({
  selector: 'kritsnam-analytics-view',
  templateUrl: './kritsnam-analytics-view.component.html',
  styleUrls: ['./kritsnam-analytics-view.component.scss']
})
export class KritsnamAnalyticsViewComponent extends BaseViewComponent implements OnInit {

  @Input('companies')
  companies: {name: string, value: number}

  @Input('users')
  users: {name: string, value: number}

  @Input('devices')
  devices: {name: string, value: number}

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {
  }

  public async onClickReset() {
    this.resetClick.emit(true);
  }
}
