<user-alerts-view
style="width: 100%; height: 100%"
[user]="user"
[mappings]="mappings"
[selectedMapping]="selectedMapping"
[data]="data"
[bannerText]="bannerText"
[inProgressCounter]="inProgressCounter"
[uiState]="uiState"
[errorText]="errorText"
[snackbar]="snackBar"
(resetClick)="onClickReset()"
(newAlertClick)="onClickNewAlert()"
(testAlertClick)="onClickTestAlert($event)"
(editAlertClick)="onClickEditAlert($event)"
(deleteAlertClick)="onClickDeleteAlert($event)"
(mappingChangeClick)="onClickMappingChange($event)"
></user-alerts-view>
