import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { CompanyModel } from 'src/app/models/company_model';
import { BackendService } from 'src/app/services/backend.service';
import { LocalService } from 'src/app/services/local.service';
import { SocketService } from 'src/app/services/socket.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyApi {

  constructor(
    private localService: LocalService,
    private backendService: BackendService,
    private socketService: SocketService,
  ) {
  }

  public getAllCompaniesObservable(): Observable<CompanyModel[]> {
    const endpoint = `/platform/companies`;
    const observedEndpoint = this.socketService.observableOf(endpoint);
    return observedEndpoint.pipe(flatMap(async (trigger: boolean) => {
      const data = await this.backendService.get(endpoint).toPromise();
      const results: CompanyModel[] = data.map(company => CompanyModel.parse(company));
      return results;
    }))
  }

  private companyCache: {[userId: string]: CompanyModel} = {};

  public async getCachedCompany(userId: string): Promise<CompanyModel> {
    if(!this.companyCache[userId]) {
      const data = await this.backendService.get(`/users/${userId}/company`).toPromise();
      const company = CompanyModel.parse(data[0]);
      this.companyCache[userId] = company;
    }
    return this.companyCache[userId];
  }
}
