import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment-timezone';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { DeviceViewModel } from 'src/app/models/device_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { LocalService } from 'src/app/services/local.service';
import * as constants from 'src/app/utils/constants';
import * as formatter from 'src/app/utils/formatter';
import * as exporter from 'src/app/utils/exporter';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import { GroupViewModel } from 'src/app/models/group_model';
import { E } from '@angular/cdk/keycodes';
import { CompanyModel } from 'src/app/models/company_model';

@Component({
  selector: 'user-dashboard-view',
  templateUrl: './user-dashboard-view.component.html',
  styleUrls: ['./user-dashboard-view.component.scss']
})
export class UserDashboardViewComponent extends BaseViewComponent implements OnInit, OnChanges {

  @Input('userId')
  userId: string;

  @Input('user')
  user: UserViewModel;

  @Input('company')
  company: CompanyModel;

  @Input('companyName')
  companyName: string;

  @Input('mappings')
  mappings: Array<MappingViewModel>;

  @Input('selectedMapping')
  selectedMapping: MappingViewModel;

  @Input('selectedDate')
  selectedDate: moment.Moment;

  @Input('selectedTarget')
  selectedTarget: DeviceViewModel | GroupViewModel;

  @Input('raw')
  raw: {serial_number:string, time: Date, forward_flow: number, workhour: number}[];

  @Input('shiftwise')
  shiftwise: DatumViewModel[];

  @Input('daily')
  daily: DatumViewModel;

  @Input('weekly')
  weekly: DatumViewModel[];

  @Input('inProgressCounter')
  inProgressCounter: number;

  @Input('units')
  units: string;

  @Input('unitDetails')
  unitDetails: {
    volume: number,
    time: number,
    symbols: { volume:string, time:string, flowrate:string }
  }

  @Output('mappingChangeClick')
  mappingChangeClick = new EventEmitter<MappingViewModel>();

  @Output('dateChangeClick')
  dateChangeClick = new EventEmitter<any>();

  @Output('shiftReportClick')
  shiftReportClick = new EventEmitter<boolean>();

  @Output('dailyReportClick')
  dailyReportClick = new EventEmitter<boolean>();

  @Output('monthlyReportClick')
  monthlyReportClick = new EventEmitter<boolean>();

  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  @Output('deviceCertificateClick')
  deviceCertificateClick = new EventEmitter<boolean>();

  today = new Date();
  lastcommunicationdate:any;
  weeklyChartData: Array<{name: string, value: number}> = [];
  yTicks: Array<number> = [];
  weeklyChartColorScheme = { domain: [constants.getCssVariable('--primary-color')] };
  shiftChartData: Array<{name: string, series: Array<{name: string, value: number}>}> = [];
  shiftChartColorScheme = { domain: ['#31C3DF', '#FFD47A', '#DC189C'] };
  dailyShifts: any[];

  columnDefs = [

    { field: 'Timestamp', valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD hh:mm:ss a'), cellStyle: {'text-align': 'right'}},
    { field: 'Cumulative Volume', valueFormatter: (params) => formatter.dateIsSameOrAfter(params.data.Timestamp) && params.value === 0 ? "Nil" : formatter.numberWithCommas(Math.round(100*params.value*this.unitDetails.volume)/100) + ` ${this.unitDetails.symbols.volume}`, cellStyle: {'text-align': 'right'}},
    { field: 'Work Hours', valueFormatter: (params) => formatter.dateIsSameOrAfter(params.data.Timestamp) && params.value === 0 ? "Nil" : formatter.getWorkHourString(params.value), cellStyle: {'text-align': 'right'}},
  ];

  rawTableData = [];

  agGridContext = {
    timezone: null
  };

  @ViewChild('agGrid') agGrid: AgGridAngular;
  private api: GridApi;
  private columnApi: ColumnApi;

  constructor(
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    snackBar: MatSnackBar,
    private localService: LocalService,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.uiState !== this.ViewUiState.data) return;

    this.weeklyChartData = this.weekly.map(w => ({
      'name': `${moment(w.start_time).format('DD/MM')} to ${moment(w.end_time).format('DD/MM')}`,
      'value': w.net_volume*this.unitDetails.volume
    }));
    let maxYValue = Math.max(...this.weekly.map(w => w.net_volume*this.unitDetails.volume));
    maxYValue = Math.max(maxYValue,this.selectedTarget.dailyNocLimit*7*this.unitDetails.volume);
    let magnitude = Math.floor(Math.log10(maxYValue));
    magnitude = Math.pow(10,magnitude);
    maxYValue = Math.ceil(maxYValue/magnitude)*magnitude;
    this.yTicks = [0,0.25*maxYValue,0.5*maxYValue, 0.75*maxYValue,maxYValue];
    let shiftNotStarted = false;
    let shiftCounter = 1;
    let dayStart = this.selectedDate.clone().startOf('day')
    let dayEnd = this.selectedDate.clone().startOf('day').add(1, 'day');
    if(this.selectedTarget.externalId.includes('group')){
      const shifts = this.selectedTarget.softwareShifts;
      const shift = shifts.split(",");
      const firstShift = shift[0].split("-");
      const [hour, min] = firstShift[0].split(":");
      dayStart = dayStart.clone().startOf('day').add(hour, 'h').add(min, 'm').add(1, 's');
      dayEnd = dayStart.clone().startOf('day').add(1, 'day').add(hour, 'h').add(min, 'm').add(1, 's');
     this.lastcommunicationdate='0';
    }else{
      const shifts = this.selectedTarget.hardwareShifts;
      const shift = shifts.split(",");
      const [hour, min] = shift[0].split(":");
      dayStart = dayStart.clone().startOf('day').add(hour, 'h').add(min, 'm').add(1, 's');
      dayEnd = dayStart.clone().startOf('day').add(1, 'day').add(hour, 'h').add(min, 'm').add(1, 's');
      if (this.raw.length > 0 ){
        this.lastcommunicationdate = this.raw[0].time;
      }
      else{
        this.lastcommunicationdate ='0';
      }

    }
    this.dailyShifts = this.shiftwise
      .filter(s => moment(s.end_time) > dayStart && moment(s.end_time) <= dayEnd)
      .map(s => {
        const start = moment(s.start_time).add(5, 'minute').startOf('hour').format('hh:mm a')
        let label = `${start}`;
        if(!this.mobileView) label = `Shift ${shiftCounter}: ${label}`
        shiftCounter++;
        if(shiftNotStarted) {
          label = `${label} (Not Started)`
        } else if(moment(s.end_time).isAfter(moment.tz('Asia/Kolkata'))) {
          label = `${label} (In Progress)`;
          shiftNotStarted = true;
        } else {
          label = `${label} (Completed)`;
        };
        return {
          name: label,
          value: s.net_volume * this.unitDetails.volume,
        }
      });

    this.shiftChartData = [];
    for(let s of this.shiftwise) {
      const t = moment(s.start_time);
      const d = moment(s.end_time).startOf('day');
      // if(t.isSame(d)) d.subtract(1, 'day'); // Midnight reading is counted in previous day
      const label = `${t.format('DD/MM')} to ${d.clone().format('DD/MM')}`
      let dataOfDay = this.shiftChartData.find(d => d.name === label);
      if(t.isBefore(d, 'date')){
        const label = `${t.format('DD/MM')} to ${t.clone().format('DD/MM')}`
        dataOfDay = this.shiftChartData.find(d => d.name === label);
        dataOfDay.name = `${t.format('DD/MM')} to ${d.clone().format('DD/MM')}`
      }
      if(!dataOfDay) {
        this.shiftChartData.push({
          name: label,
          series: [{
            name: moment(s.start_time).add(5, 'minute').startOf('hour').format('hh:mm a') + " " + moment(s.end_time).add(5, 'minute').startOf('hour').format('hh:mm a') + ' in ' + this.unitDetails.symbols.volume,
            value: s.net_volume * this.unitDetails.volume
          }]
        });
      } else {
        dataOfDay.series.push({
          name: moment(s.start_time).add(5, 'minute').startOf('hour').format('hh:mm a') + " " + moment(s.end_time).add(5, 'minute').startOf('hour').format('hh:mm a') + ' in ' + this.unitDetails.symbols.volume,
          value: s.net_volume * this.unitDetails.volume
        });
      }
    }
    // Show the table for raw data
    this.rawTableData = this.raw.map(s => ({
      'Serial Number':s.serial_number,
      'Timestamp': s.time,
      'Work Hours': s.workhour,
      'Cumulative Volume': s.forward_flow,
    }));
  }

  async onClickMappingChange(mapping: MappingViewModel) {
    this.mappingChangeClick.emit(mapping);
  }

  gridReady(params: GridReadyEvent) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.autoSizeAll(false);
    const grid = document.getElementsByClassName('ag-center-cols-container')[0]
    const gridWidth = (grid as HTMLElement).offsetWidth;
    const screenWidth = document.body.offsetWidth;
    if (screenWidth > gridWidth) {
      this.api.sizeColumnsToFit();
    }
  }

  autoSizeAll(skipHeader: boolean) {
    var allColumnIds = [];
    this.columnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  async onClickDateChange(date: any) {
    this.dateChangeClick.emit(date);
  }

  async onClickShiftReport() {
    this.shiftReportClick.emit(true);
  }

  async onClickDailyReport() {
    this.dailyReportClick.emit(true);
  }

  async onClickMonthlyReport() {
    this.monthlyReportClick.emit(true);
  }

  async onClickDeviceCertificate() {
    this.deviceCertificateClick.emit(true);
  }

  async onClickReset() {
    this.resetClick.emit(true);
  }

  public shiftCardValueFormatting  = (data) => {
    let num = (typeof(data) === 'number') ? data : data.value;
    return formatter.numberWithCommas(Math.round(num*100)/100) + ' ' + this.unitDetails.symbols.volume;
  }

  async onClickDownload() {
    const fileName = `${this.company.name} Raw Data ${this.selectedDate.format('MMM YYYY')}`;
    const sheetName = 'Raw';
    exporter.exportRawData(this.company.name, fileName, sheetName, this.raw);
  }

  async onClickDownloadMonthlyReport() {
    // Open in new tab because window.print breaks flexbox styling
    // Flexbox breakpoints don't run! so fuck it, opening it in new tab
    const month = this.selectedDate.format("MMMM");
    const queryType = this.selectedTarget.isGroup ? 'group' : 'device';
    const queryId = this.selectedTarget.externalId;
    const queryYear = this.selectedDate.year();
    let unit = ''
    if(this.units === 'CubicMeters'){
      unit = 'm3'
    } else if (this.units === 'Gallons'){
      unit = 'gal'
    } else {
      unit = 'l'
    }
    const id = this.localService.getLocalUser().externalId;
    //const id = queryId;
    console.log("queryId",queryId);
    window.open(`https://fmd.kritsnam.in/test_monthly?month=${month}&id=${id}&year=${queryYear}&unit=${unit}&device=${queryId}`, '_blank')
  }
}
