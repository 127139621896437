import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as constants from 'src/app/utils/constants';

@Component({
  selector: 'app-toolbar-view',
  templateUrl: './toolbar-view.component.html',
  styleUrls: ['./toolbar-view.component.scss']
})
export class ToolbarViewComponent implements OnInit {

  @Input('userImg')
  userImg: string;

  @Input('userName')
  userName: string;

  @Input('title')
  title: string;

  @Input('showBackButton')
  showBackButton: boolean;

  constructor() { }

  @Output('logoutClick')
  logoutClick = new EventEmitter<boolean>();

  @Output('themeClick')
  themeClick = new EventEmitter<boolean>();

  @Output('unitsClick')
  unitsClick = new EventEmitter<string>();

  @Output('backClick')
  backClick = new EventEmitter<boolean>();

  toolbarTitle: string;

  ngOnInit(): void {
    this.toolbarTitle = this.title || constants.getCssVariable('--toolbar-title');
    this.showBackButton = this.showBackButton;
  }

  onClickLogout() {
    this.logoutClick.emit(true);
  }

  onClickTheme() {
    this.themeClick.emit(true);
  }

  onClickToggleUnits(unit: string){
    this.unitsClick.emit(unit)
  }

  onClickBack() {
    this.backClick.emit(true);
  }
}
