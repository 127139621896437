<div id="kritsnam-analytics-page" fxFlex="1 1 auto" fxLayout="column">
  <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
  </app-loading-view>

  <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}"
  [errorText]="errorText" (resetClick)="onClickReset()">
  </app-error-view>

  <single-column-view *ngIf="uiState == ViewUiState.data"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    <mat-card-title style="padding: 16px;">Platform Analytics</mat-card-title>
    <mat-card-subtitle style="padding: 16px;">Please request software team to add frequently required analytics to this page</mat-card-subtitle>
    <mat-card-content
    [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}"
    [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 16, 'padding-bottom.px': 16, 'padding-left.px': 16}">
      <div fxLayout="row wrap" fxLayoutAlign="space-around center">
        <div fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
          <ngx-charts-number-card
          style="max-height: 150px; padding-left: 8px; pointer-events: none;"
          bandColor=#31C3DF
          cardColor=#232837
          [results]="[companies]">
          </ngx-charts-number-card>
        </div>
        <div fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
          <ngx-charts-number-card
          style="max-height: 150px; padding-left: 8px; pointer-events: none;"
          bandColor=#FFD47A
          cardColor=#232837
          [results]="[users]">
          </ngx-charts-number-card>
        </div>
        <div fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
          <ngx-charts-number-card
          style="max-height: 150px; padding-left: 8px; pointer-events: none;"
          bandColor=#DC189C
          cardColor=#232837
          [results]="[devices]">
          </ngx-charts-number-card>
        </div>
      </div>
    </mat-card-content>
  </single-column-view>
</div>
