import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { ROUTE_KRITSNAM_ANALYTICS, ROUTE_KRITSNAM_CLIENTS, ROUTE_KRITSNAM_NOTIFICATIONS } from 'src/app/utils/constants';

@Component({
  selector: 'app-kritsnam-home',
  templateUrl: './kritsnam-home.component.html',
  styleUrls: ['./kritsnam-home.component.scss']
})
export class KritsnamHomeComponent extends BaseComponent implements OnInit {
  mobileView = false; // Used to alter UI for mobile view

  navLinks: any[] = [
    {
      label: 'Analytics',
      link: ROUTE_KRITSNAM_ANALYTICS,
      icon: 'analytics'
    }, {
      label: 'Clients',
      link: ROUTE_KRITSNAM_CLIENTS,
      icon: 'people'
    }, {
      label: 'Notifications',
      link: ROUTE_KRITSNAM_NOTIFICATIONS,
      icon: 'notification_add'
    },
  ];

  constructor(
    router: Router,
    butler: DataService,
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
  ) {
    super(scrollingService, router, butler);
    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .subscribe(result => { this.mobileView = result.matches });
  }

  ngOnInit(): void {
    this.scrollingService.get().subscribe(s => this.scrollingDown = s);
  }
}
