import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/base/base.component';
import { UserModel, UserViewModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { SELF_KEYWORD, ViewUiState } from 'src/app/utils/constants';

@Component({
  selector: 'app-user-issues',
  templateUrl: './user-issues.component.html',
  styleUrls: ['./user-issues.component.scss']
})
export class UserIssuesComponent extends BaseComponent implements OnInit {

  user: UserViewModel;
  user$: Subscription;
  userId: string;

  inProgressCounter = 0;

  constructor(
    scrollingService: ScrollingService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    router: Router,
    butler: DataService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.parent.paramMap.get('id');
    if(this.userId === SELF_KEYWORD) {
      if(this.butler.localService.isUserKritsnam()) this.router.navigate(["/"]);
      this.userId = this.butler.localService.getLocalUserId();
    }
    this.uiState = ViewUiState.data;
  }

  ngOnInit(): void {
    this.fetchData()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  private unsubscribeAll() {
    this.user$?.unsubscribe();
  }

  private async fetchData() {
    this.unsubscribeAll();
  }

  public async onClickReset() {
    this.fetchData();
  }
}
