import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ROUTE_CGWA, ROUTE_KRITSNAM, ROUTE_USER, SELF_KEYWORD } from 'src/app/utils/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(butler: DataService, router: Router) {
    // Check user type and route accordingly
    if(butler.localService.isUserCGWA()) {
      router.navigate([ROUTE_CGWA], { replaceUrl: true, queryParamsHandling: 'preserve' });
    } else if(butler.localService.isUserKritsnam()) {
      router.navigate([ROUTE_KRITSNAM, SELF_KEYWORD], { replaceUrl: true, queryParamsHandling: 'preserve' });
    } else {
      router.navigate([ROUTE_USER, SELF_KEYWORD], { replaceUrl: true, queryParamsHandling: 'preserve' });
    }
  }

  ngOnInit(): void {
  }

}
