<h2 id="alert-edit-popup" mat-dialog-title>{{ data.alert ? 'Edit Alert': 'Create Alert' }}</h2>
<div mat-dialog-content>
  <div *ngIf="alertDescriptionMessage" style="padding: 0px 16px 16px 16px"><b>Alert Description:</b> {{alertDescriptionMessage}}</div>
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="space-around stretch" style="padding-bottom: 16px;">
    <mat-form-field appearance="fill">
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priority">
        <mat-option *ngFor="let priority of AlertPriorityValues" [value]="priority">
          {{ priority }}
        </mat-option>
      </mat-select>
      <mat-hint>Included in alert messages. Doesn't affect functioning.</mat-hint>
      <mat-error *ngIf="form.controls.priority.invalid">{{getErrorMessage(form.controls.priority)}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" style="padding-top: 16px;">
      <mat-label>Period</mat-label>
      <mat-select formControlName="period">
        <mat-option *ngFor="let period of DeviceDataPeriodsValues" [value]="period">
          {{ period }}
        </mat-option>
      </mat-select>
      <mat-hint>The data period to watch.</mat-hint>
      <mat-error *ngIf="form.controls.period.invalid">{{getErrorMessage(form.controls.period)}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" style="padding-top: 16px;">
      <mat-label>Target Reading</mat-label>
      <mat-select formControlName="target">
        <mat-option *ngFor="let target of AlertTargetValues" [value]="target">
          {{ target }}
        </mat-option>
      </mat-select>
      <mat-hint>The device attribute to watch.</mat-hint>
      <mat-error *ngIf="form.controls.target.invalid">{{getErrorMessage(form.controls.target)}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" style="padding-top: 16px;">
      <mat-label>Threshold</mat-label>
      <input matInput currencyMask autocomplete="new-password" formControlName="threshold">
      <mat-hint>Send alert at this value</mat-hint>
      <mat-error *ngIf="form.controls.threshold.invalid">{{getErrorMessage(form.controls.threshold)}}</mat-error>
    </mat-form-field>
    <!-- Email list input -->
    <mat-form-field appearance="fill" style="padding-top: 16px;">
      <mat-label>Emails</mat-label>
      <mat-chip-list #chipListEmail>
        <mat-chip *ngFor="let email of emailFormArray.controls"
        [color]="email.valid? 'neutral': 'warn'" selected (removed)="removeEmail(email)">
          {{email.value}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #emailInput
        autocomplete="new-password"
        placeholder="Input email..."
        [formControl]="emailCtrl"
        [androidMatChipInputFor]="chipListEmail"
        (matChipInputTokenEnd)="addEmail($event)">
      </mat-chip-list>
      <mat-hint>Space separated list of emails</mat-hint>
      <mat-error>{{getErrorMessage(emailFormArray)}}</mat-error>
    </mat-form-field>
    <!-- Phone chip input -->
    <mat-form-field appearance="fill" style="padding-top: 16px;">
      <mat-label>Phones</mat-label>
      <mat-chip-list #chipListPhone>
        <mat-chip *ngFor="let phone of phoneFormArray.controls"
        [color]="phone.valid? 'neutral': 'warn'" selected (removed)="removePhone(phone)">
          {{formatPhone(phone.value)}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #phoneInput
        autocomplete="new-password"
        placeholder="Input phone..."
        [formControl]="phoneCtrl"
        [matAutocomplete]="autoPhone"
        [androidMatChipInputFor]="chipListPhone"
        (matChipInputTokenEnd)="addPhone($event)">
      </mat-chip-list>
      <mat-hint>Space separated list of phones</mat-hint>
      <mat-error>{{getErrorMessage(phoneFormArray)}}</mat-error>
      <mat-autocomplete #autoPhone="matAutocomplete" (optionSelected)="selectedPhone($event)">
        <mat-option *ngFor="let user of filteredPhoneUsers | async" [value]="user.phone">
          {{user.name}} ({{user.phone}})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- Fcm chip input -->
    <mat-form-field appearance="fill" style="padding-top: 16px;">
      <mat-label>Push Notifications</mat-label>
      <mat-chip-list #chipListFcm>
        <mat-chip *ngFor="let fcm of fcmFormArray.controls"
        [color]="fcm.valid? 'neutral': 'warn'" selected (removed)="removeFcm(fcm)">
          {{formatFcm(fcm.value)}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #fcmInput
        autocomplete="new-password"
        placeholder="Input name..."
        [formControl]="fcmCtrl"
        [matAutocomplete]="autoFcm"
        [androidMatChipInputFor]="chipListFcm"
        (matChipInputTokenEnd)="addFcm($event)">
      </mat-chip-list>
      <mat-hint>Space separated list of users</mat-hint>
      <mat-error>{{getErrorMessage(fcmFormArray)}}</mat-error>
      <mat-autocomplete #autoFcm="matAutocomplete" (optionSelected)="selectedFcm($event)">
        <mat-option *ngFor="let user of filteredFcmUsers | async" [value]="user.id">
          {{user.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="form.hasError('atLeastOneRequired')" style='padding: 16px'>
      {{form.getError('atLeastOneRequired').text}}
    </div>
    <div *ngIf="form.hasError('alertNotUnique')" style='padding: 16px'>
      {{form.getError('alertNotUnique').text}}
    </div>
  </form>
</div>
<mat-dialog-actions fxLayout="row" fxLayout="row" fxLayoutAlign="end stretch">
  <button mat-button (click)="onClickCancel()">Cancel</button>
  <button mat-button id="submit-button"
  [disabled]="!form.valid && !form.disabled"
  (click)="onClickConfirm()">Confirm</button>
</mat-dialog-actions>
