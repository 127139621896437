import Excel from 'exceljs';
import moment from 'moment-timezone';
import * as constants from 'src/app/utils/constants';
import { DatumViewModel } from 'src/app/models/datum_model';
import { LocalService } from '../services/local.service';
import { dateIsSameOrAfter } from './formatter';

const localService = new LocalService()

export async function exportRawData(companyName:string,fileName: string, sheetName: string, data: {serial_number:string, time: Date, forward_flow: number, workhour: number}[]) {
  // Initialize excel file
  const workbook = new Excel.Workbook();
  const now = moment.tz('Asia/Kolkata').tz(constants.GLOBAL_TIMEZONE).format('YYYY-MM-DD hh:mm:ss a');
  workbook.creator = `Export for ${now}`;
  workbook.lastModifiedBy = 'Kritsnam Tech Team';
  const sheet = workbook.addWorksheet(sheetName);
  // Write columns
  sheet.columns = [
    { header: 'Serial Number', key: 'serial_number' },
    { header: 'Timestamp', key: 'time' },
    { header: 'Net Volume in ' + localService.getUnitDetails().symbols.volume, key: 'forward_flow' },
    { header: 'Work Hours in '+localService.getUnitDetails().symbols.time, key: 'workhour' },
  ];
  sheet.properties.defaultColWidth = 30;

  const ff = 'Net Volume in ' + localService.getUnitDetails().symbols.volume;
  const ffs = 'Work Hours in ' + localService.getUnitDetails().symbols.time;
 // sheet.addRow([]);



  sheet.mergeCells('A1:D1');
  // sheet.getCell('A1').value = companyName;
  sheet.getCell('A1').alignment = { horizontal: 'center' };
  // tslint:disable-next-line:max-line-length
  sheet.getCell('A1').value = {'richText': [{'font': {'size': 25, 'name': 'Calibri','scheme': 'minor'},'text': companyName}]};

 // { size: 25,'color': {'argb': 'FFCCFFCC'},bgColor:{argb:'ABF7B1'}, bold: true };
  sheet.getCell('A1').fill = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'90ee90'},

  };

  sheet.addRow(['Serial Number', 'Timestamp', ff, ffs]);
  sheet.getRow(2).alignment = { horizontal: 'center' };
  sheet.getRow(2).font = { bold: true };
  sheet.getCell('A2').fill= {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffff00'},

  };
  sheet.getCell('B2').fill= {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffff00'},

  };
  sheet.getCell('C2').fill= {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffff00'},

  };
  sheet.getCell('D2').fill= {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffff00'},

  };

  // Write rows
  const rows = data.map(s => ({
    serial_number: s.serial_number,
    time: moment.tz(s.time, constants.GLOBAL_TIMEZONE).format('YYYY-MM-DD hh:mm:ss a'),
    forward_flow: dateIsSameOrAfter(s.time) && s.forward_flow === 0 ? "Nil" : s.forward_flow * localService.getUnitDetails().volume,
    workhour: dateIsSameOrAfter(s.time) && s.workhour === 0 ? "Nil" : s.workhour/3600 * localService.getUnitDetails().time,
  }));
  sheet.addRows(rows);
  // Column row bold
  sheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
  });
  // Create file
  const buffer: any = await workbook.xlsx.writeBuffer();
  fileName = `${fileName} ${now}.xlsx`;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  triggerDownload(buffer, fileName, fileType);
  return;
}

export async function exportData(fileName: string, sheetName: string, data: DatumViewModel[]) {
  // Initialize excel file
  const workbook = new Excel.Workbook();
  const now = moment.tz('Asia/Kolkata').tz(constants.GLOBAL_TIMEZONE).format('YYYY-MM-DD hh:mm:ss a');
  workbook.creator = `Export for ${now}`;
  workbook.lastModifiedBy = 'Kritsnam Tech Team';
  const sheet = workbook.addWorksheet(sheetName);
  // Write columns
  sheet.columns = getColumns();
  sheet.properties.defaultColWidth = 30;
  // Write rows
  const rows = data.map(l => getRow(l));
  sheet.addRows(rows);
  // Column row bold
  sheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
  });
  // Create file
  const buffer: any = await workbook.xlsx.writeBuffer();
  fileName = `${fileName} ${now}.xlsx`;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  triggerDownload(buffer, fileName, fileType);
  return;
}

function triggerDownload(content: Buffer, fileName: string, fileType: string) {
  const blob = new Blob([content], {type: fileType});
  var a: any = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  const url= window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

function getColumns() {
  return [
    { header: 'Start Time', key: 'start_time' },
    { header: 'End Time', key: 'end_time' },
    { header: 'Work Hours in '+localService.getUnitDetails().symbols.time, key: 'workhour' },
    { header: 'Net Volume in '+localService.getUnitDetails().symbols.volume, key: 'net_volume' },
    { header: 'Flow Rate in '+localService.getUnitDetails().symbols.flowrate, key: 'flowrate' },
  ];
}

function getRow(s: DatumViewModel) {
  return {
    start_time: moment.tz(s.start_time, constants.GLOBAL_TIMEZONE).format('YYYY-MM-DD hh:mm:ss a'),
    end_time: moment.tz(s.end_time, constants.GLOBAL_TIMEZONE).format('YYYY-MM-DD hh:mm:ss a'),
    workhour: dateIsSameOrAfter(s.start_time) && s.workhour === 0 ? "Nil" : s.workhour/3600 * localService.getUnitDetails().time,
    net_volume: dateIsSameOrAfter(s.start_time) && s.net_volume === 0 ? "Nil" : s.net_volume * localService.getUnitDetails().volume,
    flowrate: dateIsSameOrAfter(s.start_time) && s.flowrate === 0 ? "Nil" : s.flowrate * localService.getUnitDetails().volume/localService.getUnitDetails().time,
  }
}
