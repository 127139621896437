import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DataService } from 'src/app/services/data.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { ROUTE_USER_ALERTS, ROUTE_USER_ANALYTICS, ROUTE_USER_ISSUES } from 'src/app/utils/constants';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent extends BaseComponent implements OnInit {
  mobileView = false; // Used to alter UI for mobile view

  navLinks: any[] = [
    {
      label: 'Analytics',
      link: ROUTE_USER_ANALYTICS,
      icon: 'analytics'
    },
    // {
    //   label: 'Alerts',
    //   link: ROUTE_USER_ALERTS,
    //   icon: 'add_alert'
    // },
    {
      label: 'Support',
      link: ROUTE_USER_ISSUES,
      icon: 'support_agent'
    },
  ];

  constructor(
    router: Router,
    butler: DataService,
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
  ) {
    super(scrollingService, router, butler);
    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .subscribe(result => { this.mobileView = result.matches });
  }

  ngOnInit(): void {}
}
