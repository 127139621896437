import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgFormsManager } from '@ngneat/forms-manager';
import { BaseViewComponent } from 'src/app/components/base/base-view/base-view.component';
import { ScrollingService } from 'src/app/services/scrolling.service';

const NOTIFICATION_FORM = 'NOTIFICATION_FORM';

@Component({
  selector: 'kritsnam-notification-view',
  templateUrl: './kritsnam-notification-view.component.html',
  styleUrls: ['./kritsnam-notification-view.component.scss']
})
export class KritsnamNotificationViewComponent extends BaseViewComponent implements OnInit {
  @Output('resetClick')
  resetClick = new EventEmitter<boolean>();

  @Output('submitClick')
  submitClick = new EventEmitter<{title: string, message: string, url: string}>();

  notificationForm: FormGroup;

  constructor(
    private readonly formsManager: NgFormsManager,
    scrollingService: ScrollingService,
    breakpointObserver: BreakpointObserver,
    private readonly formBuilder: FormBuilder,
    snackBar: MatSnackBar,
  ) {
    super(scrollingService, breakpointObserver, snackBar);
  }

  ngOnInit(): void {
    this.notificationForm = this.formBuilder.group({
      title: [ null, Validators.required ],
      message: [ null, Validators.required ],
      url: [ null ],
    });
    let formsManagerOptions = { persistState: true };
    this.formsManager.upsert(NOTIFICATION_FORM, this.notificationForm, formsManagerOptions);
  }

  public async onClickFormSubmit() {
    this.submitClick.emit(this.notificationForm.value);
  }

  ngOnDestroy() {
    this.formsManager.unsubscribe(NOTIFICATION_FORM);
  }

  public async onClickReset() {
    this.resetClick.emit(true);
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }
}
