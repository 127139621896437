import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { versions } from 'src/environments/versions';

const rollbarConfig:Rollbar.Configuration = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.rollbarEnv,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: versions.revision.trim(),
        guess_uncaught_frames: true
      }
    },
    server: {
      root: "./"
    }
  },
  transform: function(payload: any) {
    var trace = payload.body.trace;
    if (trace && trace.frames) {
      for (var i = 0; i < trace.frames.length; i++) {
        var filename = trace.frames[i].filename;
        if (filename) {
          trace.frames[i].filename = filename.split('/').pop();
        }
      }
    }
  }
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    if (environment.rollbarAccessToken && environment.rollbarAccessToken !== '') {
      this.rollbar.error(err.originalError || err);
    } else {
      throw err;
    }
  }
}
