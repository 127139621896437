import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgGridModule } from 'ag-grid-angular';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from "ng-recaptcha";
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { InfoPopupComponent } from 'src/app/components/info-popup/info-popup.component';
import { TableCellButtonComponent } from 'src/app/components/table-cell-button/table-cell-button.component';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from 'src/app/services/rollbar.service';
import { PosthogService } from 'src/app/services/posthog.service';
import { environment } from 'src/environments/environment';
import { AngularMaterialModule } from './angular-material.module';
import { CustomReuseStrategy, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertEditPopupComponent } from './components/alert-edit-popup/alert-edit-popup.component';
import { BaseViewComponent } from './components/base/base-view/base-view.component';
import { BaseComponent } from './components/base/base.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { ErrorViewComponent } from './components/error-view/error-view.component';
import { LoadingViewComponent } from './components/loading-view/loading-view.component';
import { NotificationPermissionPopupComponent } from './components/notification-permission-popup/notification-permission-popup.component';
import { ResponsiveRowViewComponent } from './components/responsive-row-view/responsive-row-view.component';
import { RowViewComponent } from './components/row-view/row-view.component';
import { SingleColumnViewComponent } from './components/single-column-view/single-column-view.component';
import { ToolbarViewComponent } from './components/toolbar-view/toolbar-view.component';
import { UserSelectPopupComponent } from './components/user-select-popup/user-select-popup.component';
import { CgwaViewComponent } from './pages/cgwa/cgwa-view/cgwa-view.component';
import { CgwaComponent } from './pages/cgwa/cgwa.component';
import { GotoComponent } from './pages/goto/goto.component';
import { HomeComponent } from './pages/home/home.component';
import { KritsnamAnalyticsViewComponent } from './pages/kritsnam-analytics/kritsnam-analytics-view/kritsnam-analytics-view.component';
import { KritsnamAnalyticsComponent } from './pages/kritsnam-analytics/kritsnam-analytics.component';
import { KritsnamClientsViewComponent } from './pages/kritsnam-clients/kritsnam-clients-view/kritsnam-clients-view.component';
import { KritsnamClientsComponent } from './pages/kritsnam-clients/kritsnam-clients.component';
import { KritsnamHomeComponent } from './pages/kritsnam-home/kritsnam-home.component';
import { KritsnamNotificationViewComponent } from './pages/kritsnam-notification/kritsnam-notification-view/kritsnam-notification-view.component';
import { KritsnamNotificationComponent } from './pages/kritsnam-notification/kritsnam-notification.component';
import { KritsnamProfileViewComponent } from './pages/kritsnam-profile/kritsnam-profile-view/kritsnam-profile-view.component';
import { KritsnamProfileComponent } from './pages/kritsnam-profile/kritsnam-profile.component';
import { LoginViewComponent } from './pages/login/login-view/login-view.component';
import { LoginComponent } from './pages/login/login.component';
import { ReportDailyViewComponent } from './pages/report-daily/report-daily-view/report-daily-view.component';
import { ReportDailyComponent } from './pages/report-daily/report-daily.component';
import { ReportMonthlyViewComponent } from './pages/report-monthly/report-monthly-view/report-monthly-view.component';
import { ReportMonthlyComponent } from './pages/report-monthly/report-monthly.component';
import { ReportShiftViewComponent } from './pages/report-shift/report-shift-view/report-shift-view.component';
import { ReportShiftComponent } from './pages/report-shift/report-shift.component';
import { UserAlertsViewComponent } from './pages/user-alerts/user-alerts-view/user-alerts-view.component';
import { UserAlertsComponent } from './pages/user-alerts/user-alerts.component';
import { UserDashboardViewComponent } from './pages/user-dashboard/user-dashboard-view/user-dashboard-view.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { UserHomeComponent } from './pages/user-home/user-home.component';
import { UserIssuesViewComponent } from './pages/user-issues/user-issues-view/user-issues-view.component';
import { UserIssuesComponent } from './pages/user-issues/user-issues.component';
import { UserProfileViewComponent } from './pages/user-profile/user-profile-view/user-profile-view.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AndroidMatChipInput } from './utils/android-mat-chip-input.directive';
import { TOKEN_KEY } from './utils/constants';
import { ScrollDirectionDirective } from './utils/scroll-direction.directive';
import { CgwaPrintComponent } from './pages/cgwa/cgwa-print/cgwa-print.component';
import { ReportDailyPrintComponent } from './pages/report-daily/report-daily-print/report-daily-print.component';
import { ReportMonthlyPrintComponent } from './pages/report-monthly/report-monthly-print/report-monthly-print.component';
import { ReportShiftPrintComponent } from './pages/report-shift/report-shift-print/report-shift-print.component';
import { UserDashboardPrintComponent } from './pages/user-dashboard/user-dashboard-print/user-dashboard-print.component';
import { RouteReuseStrategy } from '@angular/router';
import { BarVerticalComponent } from './components/custom-charts/bar-chart/bar-vertical.component';
import { AreaChartComponent } from './components/custom-charts/area-chart/area-chart.component';

import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Kolkata');

export function tokenGetterFunction() {
  return localStorage.getItem(TOKEN_KEY);
}

const config: SocketIoConfig = { url: environment.apiUrl, options: {} };

export function initializeApp(posthogService: PosthogService) {
  return () => {
    return posthogService.initPostHog();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginViewComponent,
    UserHomeComponent,
    BaseComponent,
    BaseViewComponent,
    ToolbarViewComponent,
    ErrorViewComponent,
    LoadingViewComponent,
    SingleColumnViewComponent,
    ConfirmPopupComponent,
    ResponsiveRowViewComponent,
    RowViewComponent,
    HomeComponent,
    UserSelectPopupComponent,
    ScrollDirectionDirective,
    KritsnamHomeComponent,
    UserProfileComponent,
    UserProfileViewComponent,
    KritsnamProfileComponent,
    KritsnamProfileViewComponent,
    UserDashboardComponent,
    UserDashboardViewComponent,
    ReportShiftComponent,
    ReportDailyComponent,
    ReportMonthlyComponent,
    ReportShiftViewComponent,
    ReportDailyViewComponent,
    ReportMonthlyViewComponent,
    NotificationPermissionPopupComponent,
    KritsnamNotificationComponent,
    KritsnamNotificationViewComponent,
    KritsnamAnalyticsComponent,
    KritsnamAnalyticsViewComponent,
    KritsnamClientsComponent,
    KritsnamClientsViewComponent,
    GotoComponent,
    TableCellButtonComponent,
    InfoPopupComponent,
    UserAlertsComponent,
    UserAlertsViewComponent,
    UserIssuesComponent,
    UserIssuesViewComponent,
    AlertEditPopupComponent,
    AndroidMatChipInput,
    CgwaComponent,
    CgwaViewComponent,
    CgwaPrintComponent,
    ReportDailyPrintComponent,
    ReportMonthlyPrintComponent,
    ReportShiftPrintComponent,
    UserDashboardPrintComponent,
    BarVerticalComponent,
    AreaChartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetterFunction,
        headerName: 'X-Access-Token',
        authScheme: '',
        allowedDomains: [environment.jwtAllowedDomain],
      },
    }),
    MatProgressButtonsModule.forRoot(),
    NgxMatSelectSearchModule,
    SocketIoModule.forRoot(config),
    CurrencyMaskModule,
    AgGridModule.withComponents([]),
    NgxChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately'
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    // Use moment.js for DatePickers
    {provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'DD/MM/YYYY',
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }},
    // Currency format https://www.npmjs.com/package/ng2-currency-mask
    { provide: CURRENCY_MASK_CONFIG, useValue: {
      align: "left",
      allowNegative: false,
      decimal: ".",
      precision: 0,
      prefix: "₹ ",
      suffix: "",
      thousands: ","
      }
    },
    // Recaptcha Settings
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaApiKey } as RecaptchaSettings,
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [PosthogService],
      multi: true
    },
    { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: {separatorKeyCodes: [ENTER, SPACE, COMMA]}},
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ConfirmPopupComponent,
    UserSelectPopupComponent,
    InfoPopupComponent,
    AlertEditPopupComponent,
  ],
})
export class AppModule { }
