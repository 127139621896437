import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmPopupOptions {
  title: string,
  message: string,
  confirmColor: string,
}

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPopupOptions
  ) { }

  ngOnInit(): void {
  }

  onClickCancel() {
    this.dialogRef.close(false);
  }

  onClickConfirm() {
    this.dialogRef.close(true);
  }
}
